import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class NewResult extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          activeNav: 1,
          isLoaded: false,
          user: [],
          result: 0
        };
      }
    
      componentDidMount() {
        const handle  = this.props.match.params.id;
        //console.log(handle);

        this.setState({
            result: handle
        })

        setTimeout(function() {
            window.location.replace("/supplier/index/")
        }, 2500);

        // if (localStorage.getItem('token')) {
            
        // };
    }

    // setIkasp = evt => {
    //     if (localStorage.getItem('token')) { 
    //         fetch(process.env.REACT_APP_API_LINK + '/api/suppliers/newinstance/', {
    //             method: 'POST',
    //             headers: new Headers({
    //                 'Authorization' : 'Bearer ' + localStorage.getItem('token'),
    //             }),
    //             body: JSON.stringify({
    //                 'Ikasp': evt.target.value
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (data.Result == 1) {
    //             window.location.replace("/supplier/result/1");
    //             }
    //             else {
    //             window.location.replace("/supplier/result/0");
    //             }
    //         })
    //     }
    // }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--5" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                            {this.state.result == 1 ?
                                <Col xs="8">
                                    <h3 className="mb-0" style={{color: 'green'}}>Δηλώσατε επιτυχώς τον αριθμό αίτησης.</h3>
                                </Col>
                            : <> {this.state.result == 0 ? 
                                <Col xs="8">
                                    <h3 className="mb-0" style={{color: 'red'}}>Δεν υπάρχει ο αριθμός αίτησης που δηλώσατε.</h3>
                                </Col> : 
                                    <Col xs="8">
                                        <h3 className="mb-0" style={{color: 'red'}}>Ο αριθμός αίτησης που δηλώσατε είναι ήδη δεσμευμένος.</h3>
                                    </Col>}
                                </>
                            }
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewResult;
