import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';


class NewlyBuiltAutopsiesNotCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      loading: true
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          this.setState({
            userDetails: result
          });
        })

      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/uncompletedAutopsies/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
      })
        .then(res => res.json())
        .then((result) => {
          console.log(result);
          //console.log(result[1]['0ffa69c6-8223-4532-9fca-74a615ae31ca']);
          const instances2 = result[0];

          var self = this;
          instances2.forEach(function (part, index) {
            this[index].SubmittedAt = this[index].SubmittedAt.split('T')[0];
            this[index].Status = this[index].Declined == true ? 'Αυτοψία Υπό Επανεξέταση' : this[index].Status == 1 ? 'Αυτοψία Μελέτης' : this[index].Status == 3 ? 'Βεβαιωμένη Αυτοψία' : '';
            var mythis = this;

            result[1][this[index].Id].forEach(function (element, counter) {
              if (element[1] == 'd87ac0b5-bcbb-420b-913f-1a95799e9530') {
                mythis[index].meleti = element[2];
              }
              if (element[1] == 'fcf1056c-4d54-46c8-9494-0c0c0d5dc6bc') {
                mythis[index].mixanikos = element[2];
              }
              if (element[1] == '6cc71d92-7162-4f30-b38c-8a953381b571') {
                mythis[index].poli = element[2];
              }
              if (element[1] == '4cc7efc4-51f4-4b72-b74d-9cf6e23f909b') {
                mythis[index].anatheorisi = element[2];
                if (element[2] == '') {
                  mythis[index].anatheorisi = '-';
                }
              }

            }, result[1][this[index].Id]);

            //console.log(result[1][this[index].Id][1][2]);

            // this[index].ergodotis = result[1][this[index].Id][0];
            this[index].Buttons =
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/newlyBuilt/autopsy/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία
                </Button>
                {self.state.userDetails.PersonRole != 'User' ?
                  <>
                    {this[index].Status != 'Βεβαιωμένη Αυτοψία' ?
                      <>
                        <Button
                          color="warning"
                          href={"/newlyBuilt/instance/" + this[index].Id}
                          size="sm"
                        >
                          Ενέργειες
                        </Button>
                      </>
                      : <></>
                    }
                  </>
                  :
                  <>
                    <Button
                      color="warning"
                      href={"/newlyBuilt/instance/" + this[index].Id}
                      size="sm"
                    >
                      Ενέργειες
                    </Button>
                  </>
                }

                <Button
                  color="success"
                  href={"/newlyBuilt/comments/" + this[index].Id}
                  size="sm"
                >
                  Σχόλια
                </Button>
                <Button
                  color="primary"
                  href={"/newlyBuilt/files/" + this[index].Id}
                  size="sm"
                >
                  Έγγραφα
                </Button>
              </React.Fragment>

          }, instances2);

          var instanceCopies = [...instances2];
          instanceCopies.forEach(object => {
            delete object['Id'];
          });
          //console.log(instanceCopies);
          {
            self.state.userDetails.PersonRole == 'Admin' ?
              <>
                {
                  this.setState({
                    loading:false,
                    datas: {
                      columns: [
                        {
                          label: 'Α/Α  ▼',
                          field: 'SerialNumber',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'συνδεδεμενη μελετη  ▼',
                          field: 'meleti',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Κατασταση ▼',
                          field: 'Status',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ημ. Τελ. Ανανεωσης ▼',
                          field: 'SubmittedAt',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ανατεθηκε Σε ▼',
                          field: 'EditedBy',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Εργοδοτης ▼',
                        //   field: 'ergodotis',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Μηχανικος ▼',
                          field: 'Name',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Πολη ▼',
                        //   field: 'poli',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Παλια Μελετη ▼',
                        //   field: 'anatheorisi',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Επιβλεπων Μηχανικος ▼',
                          field: 'SupervisingEngineerName',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Μεταβαση στην Αυτοψια ▼',
                          field: 'Buttons',
                          sort: 'asc',
                          width: 150
                        }
                      ],
                      rows: instanceCopies
                    }
                  })
                }
              </>
              :
              <>
                {
                  this.setState({
                    loading:false,
                    datas: {
                      columns: [
                        {
                          label: 'Α/Α  ▼',
                          field: 'SerialNumber',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'συνδεδεμενη μελετη  ▼',
                          field: 'meleti',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Κατασταση ▼',
                          field: 'Status',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ημ. Τελ. Ανανεωσης ▼',
                          field: 'SubmittedAt',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ανατεθηκε Σε ▼',
                          field: 'EditedBy',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Εργοδοτης ▼',
                        //   field: 'ergodotis',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Μηχανικος ▼',
                        //   field: 'mixanikos',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Πολη ▼',
                        //   field: 'poli',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Παλια Μελετη ▼',
                        //   field: 'anatheorisi',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Επιβλεπων Μηχανικος ▼',
                          field: 'SupervisingEngineerName',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Μεταβαση στην Αυτοψια ▼',
                          field: 'Buttons',
                          sort: 'asc',
                          width: 150
                        }
                      ],
                      rows: instanceCopies
                    }
                  })
                }
              </>
          }


          const instances = result[0];
          this.setState({ instances });
          //console.log(instances);
        })
    };
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Μη Βεβαιωμένες Αυτοψίες</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* <Button
                            color="success"
                            href="/supplier/newinstance"
                            size="sm"
                        >
                            Κατοχύρωση νέας αίτησης
                        </Button> */}
                    </Col>
                  </Row>

                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    data={this.state.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                    responsive
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

        </Container>
      </>
    );
  }
}

export default NewlyBuiltAutopsiesNotCompleted;
