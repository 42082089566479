import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import NewlyBuiltLayout from "layouts/NewlyBuilt.js";

import Login from "views/examples/Login.js";

import SignUp from "views/examples/signup.js"

function setToken(userToken) {
    localStorage.setItem('token', userToken);
}

async function verifyToken(token) {
    //here
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    })
        // .then(res => res.json())
        .then(
            (result) => {
                // //console.log(result.status);
                if (result.status != 200) {
                    localStorage.removeItem('token');
                    window.location.replace("/auth/login");
                }
                else {
                    localStorage.setItem('token', token);
                }
            },
            (error) => {
                // //console.log(error);
                localStorage.removeItem('token');
                window.location.replace("/auth/login");
            }
        )
};

function App() {

    const token = localStorage.getItem('token');

    if (!token) {
        if (window.location.href.includes('signup')) {
            // // //console.log(window.location.href);
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/signup" render={props => <SignUp {...props} />} />
                    </Switch>
                </BrowserRouter>
            )
        }
       
        else {
            return <Login setToken={setToken} />
        };

    }
    else {
        verifyToken(token);
        // // //console.log(token);

        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={props => <Login {...props} />} />
                    <Route path="/newlyBuilt" render={props => <NewlyBuiltLayout {...props} />} />
                    <Route path="/signup" render={props => <SignUp {...props} />} />
                    <Redirect from="/" to="/newlyBuilt/index" />
                </Switch>
            </BrowserRouter>
        );
    }

}

export default App;
