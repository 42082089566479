
import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';

import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';
// import { ApiCallerHelper } from "../../../api/apiCallerHelper";
// import { ApiCallerHelperFiles } from "../../../api/apiCallerHelper.files";
// import { WorkflowHelper } from '../../../common/workflowHelper';
// import { UserRoleHelper } from '../../../common/userRoleHelper';
// import { ValidationHelper } from '../../../common/validationHelper';
// import { RedirectHelper } from '../../../common/redirectHelper';
// import { WorkflowInstancesStatusTypes } from '../../../common/enums/workflowInstancesStatusTypes.enum';

class FilesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {
        console.log(this.props.files)
    }

    render() {
        return (
            <>
                <Row>
                    <Col lg="10">
                        {this.props.hasPermissionToUpload && <>

                            <label className="btn btn-primary" htmlFor={this.props.StepAndActionId} >
                                Επιλογή αρχείου
                            </label>
                        </>}
                        <Input
                            className="form-control-alternative"
                            // defaultValue={ dynamicActions.data.Data }
                            id={this.props.StepAndActionId}
                            // placeholder={dynamicActions.action.Description}
                            style={{ visibility: "hidden" }}
                            type='file'
                            // disabled={!dynamicActions.action.Required}
                            onChange={evt => this.props._onChange(evt, this.props.StepAndActionId)}
                            accept="application/pdf"
                            multiple
                        />
                    </Col>
                </Row>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Ονομα</th>
                            <th scope="col">Ημερομηνια Υποβολης</th>
                            <th scope="col">Ενεργειες</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.files.map((dynamicFile) =>
                                <>
                                    {this.props.actionId == dynamicFile.ActionId ?
                                        <>
                                            <tr>
                                                <td>{dynamicFile.FileName}</td>
                                                <td>{dynamicFile.UploadDate}</td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        href={dynamicFile.Link}
                                                        target="_blank"
                                                        size="sm"
                                                        title="Προβολή"
                                                    >
                                                        <i className="fas fa-eye" />
                                                    </Button>

                                                    {this.props.hasDeletePermission(dynamicFile) ?
                                                        <>
                                                            <Button
                                                                color="danger"
                                                                onClick={() => { this.props.deleteFunc(dynamicFile.Id) }}
                                                                size="sm"
                                                                title="Διαγραφή"
                                                            >
                                                                <i className="fas fa-trash" />
                                                            </Button>
                                                        </> : <></>
                                                    }

                                                </td>
                                            </tr>
                                        </> : <></>
                                    }
                                </>
                            )}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default FilesComponent;