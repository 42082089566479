import NewlyBuiltInstancesGenericViewByState from "./newlyBuiltInstancesGenericViewByState";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";

class NewlyBuiltNotCompletedInstances extends NewlyBuiltInstancesGenericViewByState {
  constructor(props) {    
    super(props);        
  }

  __PageTitle = "Μη Ολοκληρωμένα Αιτήματα" 

  async apiDataResolver() {    
    const result = await ApiCallerHelper.Workflowinstances.getUncompletedResearchesAsync();
    this.generateGenericWorkflowInstancesGridDataAndSetState(result);      
  }
}

export default NewlyBuiltNotCompletedInstances;
