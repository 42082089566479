const FILE_MANAGER_ROUTE =
  "https://filemanager.deda.gr/";


export const FileManagerHelper = {            
    postUploadFile : (formData, successCallback) => postUploadFile(formData, successCallback),      
  };

function postUploadFile(formData, successCallback) {
  var route = FILE_MANAGER_ROUTE;
  fetch(route, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {      
      successCallback(res);
    })
    .catch((error) => {
        console.error('FileManagerHelper -> postUploadFile -> Catch ', error);
    });
}