import axios from "axios";

function normalizeRoute(route) {
  let character = route.substr(-1);
  if ((character) != "/"){
    route = route + "/"
  }
  return route;
}

export async function get(route, successCallback) {
  route = normalizeRoute(route);
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await getAsync(route);
  successCallback(result) 
}

export function getAsync(route) {
  route = normalizeRoute(route);
  if (!hasInitialisedToken()) {
    return;
  }

  return axios
    .get(process.env.REACT_APP_API_LINK + route, {
      headers: getAuthorizationHeader(),
    })
    .catch((error) => {
      console.error("ApiCallerHelper -> _get -> Catch ", error);
      throw error;
    });
}

export function post(route, body, successCallback) {
  route = normalizeRoute(route);
  if (!hasInitialisedToken()) {
    return;
  }
  fetch(process.env.REACT_APP_API_LINK + route, {
    method: "POST",
    headers: new Headers(getAuthorizationHeader()),
    body: JSON.stringify(body),
  }).then((res) => {
    successCallback(res);
  });
}

export function del(route, id, successCallback) {
  route = normalizeRoute(route);
  if (!hasInitialisedToken()) {
    return;
  }
  axios
    .delete(process.env.REACT_APP_API_LINK + route + id, {
      headers: getAuthorizationHeader(),
    })
    .then((res) => {
      successCallback(res);
    })
    .catch((error) => {
      console.error("ApiCallerHelper -> _delete -> Catch ", error);
    });
}

export function getAuthorizationHeader() {
  if (!hasInitialisedToken()) {
    throw Error("Error No Token found to execute api call");
  }
  let token = localStorage.getItem("token");
  return {
    Authorization: "Bearer " + token,
  };
}

export function hasInitialisedToken() {
  if (localStorage.getItem("token")) {
    return true;
  }
  return false;
}
