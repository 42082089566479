import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import 'moment/locale/el';

class EktosSxediou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            data: [],
            serialnumbers: [],
            userDetails: [],
            userEmail: '',
            userPhone: '',
            dict: {},
            serialNumber: '',

        };

    }

    async componentDidMount() {
        document.getElementById('sidenav-main').style.display = 'none';
        document.getElementById('navbar-main').style.display = 'none';
        document.querySelector('.main-content').style.margin = '0px';
        document.querySelector('body').style.backgroundColor = '#fff';
        document.querySelector('.footer').style.backgroundColor = '#fff';
        document.querySelector('.copyright').style.display = 'none';

        if (localStorage.getItem('token')) {

            let instance = window.location.href.split('/')[5];

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);
                    this.setState({
                        userDetails: result,
                        userEmail: result.Email,
                        userPhone: ''
                    });
                })

            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/researchid/" + instance + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);
                    this.state.researchId = result;
                })

            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancedata/" + instance + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    let steps = [...result.stepsInfused];
                    this.state.serialNumber = result.instance.SerialNumber;
                    //console.log(steps);
                    var self = this;
                    steps.forEach(function (element, index) {
                        element.actions.forEach(function (part) {
                            self.state.dict[part.action.Description] = part.data.Data;
                        });

                    }, steps);
                    this.setState({
                        dict: this.state.dict,
                        serialNumber: this.state.serialNumber
                    });
                    //console.log(this.state.dict);
                    // this.setState({
                    //     userDetails: result,
                    //     userEmail: result.Email,
                    //     userPhone: ''
                    // });
                })
            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancedata/" + this.state.researchId + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    let steps = [...result.stepsInfused];
                    this.state.serialNumber = result.instance.SerialNumber;
                    //console.log(steps);
                    var self = this;
                    steps.forEach(function (element, index) {
                        element.actions.forEach(function (part) {
                            self.state.dict[part.action.Description] = part.data.Data;
                        });

                    }, steps);
                    this.setState({
                        dict: this.state.dict,
                        serialNumber: this.state.serialNumber
                    });
                    // console.log(this.state.dict);
                    // this.setState({
                    //     userDetails: result,
                    //     userEmail: result.Email,
                    //     userPhone: ''
                    // });
                })

        }
        else {
            this.props.history.goBack();
        }


    }

    print() {
        // printButton
        document.getElementById('printSection').style.display = 'none';
        window.print();
    }

    getCurrentDate() {
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        return date;
    }

    render() {
        window.onafterprint = function () {
            // //console.log("Printing completed...");
            document.getElementById('printSection').style.display = 'block';
        }
        return (
            <>

                <div class="container-fluid" style={{ margin: '0' }}>


                    {/* <GenericHeader /> */}
                    <div id="printSection" style={{ marginTop: '20px', zIndex: '1000', marginBottom: '20px' }}>

                        <Button
                            color="primary"
                            size="sm"
                            title="Στοιχεία"
                            onClick={() => this.print()}
                        >
                            Εκτύπωση
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            title="Επιστροφή στην πλατφόρμα"
                            onClick={() => window.location.href = "/newlyBuilt/index"}
                        >
                            Επιστροφή στην πλατφόρμα
                        </Button>
                    </div>

                </div>

                <div className="container-fluid">
                    <div className="row" style={{ padding: '50px' }}>
                        <div className="col-sm">
                            <img width="154" height="136" src="https://deda.gr/wp-content/uploads/2020/04/Picture1-150x150.jpg" align="left" hspace="12" alt="Logo, company name Description automatically generated" />

                        </div>
                        <div className="col-sm" style={{ marginTop: '40px' }}>
                            <h5 style={{ textAlign: 'right' }}>Αθήνα, {this.getCurrentDate()}</h5>
                            <h4 style={{ textAlign: 'right' }}>Αυξ. Αριθμ. Μελέτης ΔΕΔΑ: {this.state.serialNumber}  </h4>
                            {/* <h4 style={{ textAlign: 'right' }}>Αρ. Πρωτ. ΔΕΔΑ: ____</h4> */}

                        </div>

                    </div>
                    <p style={{ color: 'black', fontWeight: '400', marginTop: '10px' }}>
                        Προς : Υπηρεσία Δόμησης
                    </p>
                    <p style={{ color: 'black', fontWeight: '400', marginTop: '10px' }}>
                        Θέμα : Βεβαίωση Αυτοψίας σε Εκτός Σχεδίου
                    </p>

                    <div className="container">
                        <h3 style={{ textAlign: 'center' }}>ΘΕΜΑ: « Εφαρμογή των διατάξεων του άρθρου 30 παρ. 7 του Ν.3175/2003»</h3>

                        <table className="table" style={{ width: '50%', marginTop: '30px' }}>
                            {/* <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First</th>
                                    <th scope="col">Last</th>
                                    <th scope="col">Handle</th>
                                </tr>
                            </thead> */}
                            <tbody>
                                <tr>
                                    <td>Εργοδότης</td>
                                    <td>{this.state.dict["Εργοδότης"]}</td>
                                </tr>
                                <tr>
                                    <td>Έργο</td>
                                    <td>{this.state.dict["Τίτλος Έργου"]}</td>
                                </tr>
                                <tr>
                                    <td>Διεύθυνση</td>
                                    <td>{this.state.dict["Διεύθυνση"]}</td>
                                </tr>
                                <tr>
                                    <td>Νομός/Δήμος</td>
                                    <td>{this.state.dict["Νομός/Δήμος"]}</td>
                                </tr>
                                <tr>
                                    <td>Μελετητής</td>
                                    <td>{this.state.dict["Μελετητής Εσωτερικής Εγκατάστασης Αερίου"]}</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                    Σχετικά: <br /><br />

                    Σε απάντηση του αιτήματος σας και λαμβάνοντας υπόψη μας το Άρθρο 30 του Ν.3175 / 2003, την παράγραφο

                    11 της εγκυκλίου 27/04.03.2004 του ΥΠΕΧΩΔΕ και την αναφορά σας πως το κτίριο βρίσκεται σε εκτός σχεδίου

                    περιοχή, σας ενημερώνουμε πως, επειδή δεν υπάρχουν δίκτυα χαμηλής πίεσης φυσικού αερίου σε απόσταση μικρότερη των 25 μέτρων από αυτό, το εν λόγω κτίριο δεν εμπίπτει στις διατάξεις της παραγράφου 7 του Άρθρου 30 του Ν.3175/2003 και ως εκ τούτου η ΔΕΔΑ δε θα διενεργήσει αυτοψία στο ακίνητο σας.
                    <div className="col-sm">
                        {/* <img width="154" height="136" src="https://deda.gr/wp-content/uploads/2020/04/Picture1-150x150.jpg" align="left" hspace="12" alt="Logo, company name Description automatically generated" /> */}

                    </div>
                    <div className="col-sm" style={{ marginTop: '40px' }}>
                        <h3 style={{ textAlign: 'right', color: 'darkblue' }}>Για τη ΔΕΔΑ</h3>

                        <h4 style={{ textAlign: 'right', color: 'darkblue' }}>Ο εξουσιοδοτημένος μηχανικός</h4>

                    </div>
                    <br />
                    <br />
                    <br />
                    ΠΥΡΓΟΣ ΑΘΗΝΩΝ, Λ. ΜΕΣΟΓΕΙΩΝ 2-4, 11527 ΑΘΗΝΑ, ΤΗΛΕΦΩΝΟ: 216 2000 401-5, FAX: 210 2750 249
                    ΑΡΙΘΜΟΣ Γ.Ε.ΜΗ.: 141016101000

                </div>


            </>
        )
    };

}

export default EktosSxediou;