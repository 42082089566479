import React from "react";
import { UserRoleHelper } from "../../../common/userRoleHelper"
import { FileManagerHelper } from "../../../api/fileManagerHelper"
import { ApiCallerHelperFiles } from "../../../api/apiCallerHelper.files"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';


import { BlockReserveLoading, CommonLoading } from 'react-loadingg';
import Moment from "moment";

class NewlyBuiltAutopsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user: [],
      details: [],
      consumer: [],
      meter: [],
      dict1: {},
      dict3: {},
      instanceSerialNumber: '',
      instanceStatus: '',
      instanceStartedAt: '',
      instanceSubmittedAt: '',
      instanceStepName: '',
      instanceUserId: '',
      loading: true,
      files: [],
      newautopsies: [],
      researchId: '',
      instanceId: ''
      // instanceId: ''
    };
    this.SignedDocuments.bind(this);    
  }


  SignedDocuments = {
    bind : function(self) {
      self.SignedDocuments.render = self.SignedDocuments.render.bind(self);   
      self.SignedDocuments.getControlId = self.SignedDocuments.getControlId.bind(self);       
    },
    Consts : {
      workflowStepActionId : "c5ee6305-013a-43d6-a5d4-1b7e9bf9ebc4", //"20abd95c-321a-4220-8b44-a0c34be994d0", //
      workflowStepId : "32aa2071-28fb-4bcd-a6be-c7cfa36a63c0" //"858b4550-1136-4a42-80eb-e5722f8d18c7"
    },
    getControlId : function() {
      return this.SignedDocuments.Consts.workflowStepId + "/" + this.SignedDocuments.Consts.workflowStepActionId;
    },   
    uploadFiles : (evt, actionid) => {
      const formData = new FormData();
      var res = actionid.split("/");                    
      var self = this;

      function _loopUploadFile(file) {          
        const newformData = new FormData();
        newformData.append("files",file);
        FileManagerHelper.postUploadFile(newformData, (result)=> {

          let data = {
            'Name': result[0].name,
            'Path': result[0].path,
            'Size': result[0].size,
            'Type': result[0].type,
            'InstanceId': self.state.instanceId,
            'StepId': res[0],
            'ActionId': res[1]
          }

       
          ApiCallerHelperFiles.postFilesNew(data, (res)=> {   
              self.SignedDocuments.refreshData();
          })
        })
      }      

      let files = evt.target.files;        

      var self = this;
      for (let i = 0; i < files.length; i++) {
        let file = evt.target.files.item(i);
        _loopUploadFile(file);
      } 
  
    },    
    deleteFile : (fileId) => {
      var self = this;
      ApiCallerHelperFiles.deleteFileById(fileId, ()=> {
        self.SignedDocuments.refreshData();          
    })
    },
    render : function() {
      let controlId = this.SignedDocuments.getControlId();
      let addButton = (
        <>
          <label className="btn btn-primary" htmlFor={controlId}>
            Επιλογή αρχείου
          </label>
          <Input
            className="form-control-alternative"
            id={controlId}
            style={{ visibility: "hidden" }}
            type={"file"}
            onChange={(evt) => this.SignedDocuments.uploadFiles(evt, controlId)}
            accept="application/pdf"
            multiple
          />
        </>
      );

      let fileDetails = (       
        <Table className="align-items-center table-flush" style={{ marginBottom: '20px' }} responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Ονομα Αρχειου</th>
              <th scope="col">Ημερομηνια Αναρτησης</th>
              <th scope="col">Ενεργειες</th>
            </tr>
          </thead>
          <tbody>
            {this.state.files.map((dynamicFiles) =>
              <>
                {dynamicFiles.ActionId == this.SignedDocuments.Consts.workflowStepActionId &&
                  <>
                    <tr>
                      <td>{dynamicFiles.FileName}</td>
                      <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                      <td>
                        <Button
                          color="primary"
                          href={dynamicFiles.Link}
                          target="_blank"
                          size="sm"
                          title="Προβολή"
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        {UserRoleHelper.isAdmin(this.state.user) &&
                          <>
                            <Button
                              color="danger"
                              onClick={() => { this.SignedDocuments.deleteFile(dynamicFiles.Id) }}
                              size="sm"
                              title="Διαγραφή"
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </>
                        }
  
                      </td>
                    </tr>
                  </>
                }
              </>
            )}
          </tbody>
        </Table>
      )

      if (!UserRoleHelper.isAdmin(this.state.user) ) {
        addButton = ("")
      }

      return (
        <>
          <Row>
            <Col lg="10">
              <b>Υπογεγραμμένα Έγγραφα</b> <br />
              <br />
              <>
                {addButton}
                {fileDetails}
              </>
            </Col>
          </Row>
        </>
      ); 

    },   
    refreshData : ()=>{
      var self = this;
      ApiCallerHelperFiles.getFileStatusByWorkflowInstanceId(self.state.instanceId, (res)=>{
        self.setState({
          loading: false,
          files: res.data
        });
    })
    }

  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          this.setState({
            user: result
          });
        })

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/researchid/" + this.props.match.params.id + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          this.setState({
            researchId: result
          });
        })

      const handle = this.props.match.params.id;

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          this.state.instanceStatus = result.Status;

          switch (this.state.instanceStatus) {
            case 1:
              this.state.instanceStepName = 'Υποβολή Αυτοψίας';
              break;

            case 3:
              this.state.instanceStepName = 'Βεβαιωμένη Αυτοψία';
              break;

            default:
              this.state.instanceStepName = 'Κατάσταση';
          }

          this.state.instanceSerialNumber = result.SerialNumber;
          this.state.instanceStartedAt = result.StartedAt;
          this.state.instanceSubmittedAt = result.SubmittedAt;
          this.state.instanceId = result.Id;
          this.state.instanceUserId = result.UserId;
        })

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancestep/" + handle + '/step/1/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          let steps = [...result.actionsInfused];
          //console.log(steps);
          var self = this;
          steps.forEach(function (element, index) {
            self.state.dict1[element.action.Name] = element.data.Data;

          }, steps);
          delete this.state.dict1['Σχετικά Έγγραφα'];
          this.setState({
            dict1: this.state.dict1,
          });


          this.setState({
            loading: false
          });


          //console.log(this.state.dict1);
        })

    
      this.SignedDocuments.refreshData();            
    };
  }

  emailMechanic = (e) => {
    fetch(process.env.REACT_APP_API_LINK + '/api/user/emailmechanic/', {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }),
      body: JSON.stringify({
        'userid': this.state.instanceUserId,
        'instanceid': this.state.instanceId,
        'serialnumber': this.state.instanceSerialNumber,
      })
    })
      .then(res => res.json())
      .then(data => {
        //console.log(data);
      })
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <GenericHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Αυτοψίας</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          href={"/newlyBuilt/details/" + this.state.researchId}
                          color='primary'
                          size="sm"
                        >
                          Μετάβαση στην Μελέτη
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="col text-right">

                      </div>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-serialno"
                              >
                                Α/Α Αυτοψίας
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instanceSerialNumber}
                                disabled
                                id="input-serialno"
                                placeholder="Α/Α"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3" >
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-status"
                              >
                                Κατάσταση
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instanceStepName}
                                // defaultValue={Moment(this.state.details.activation).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-status"
                                placeholder="Κατάσταση"
                                type="text"
                                disabled={true}
                              // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-startedat"
                              >
                                Ημερομηνία Ενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-startedat"
                                defaultValue={this.state.instanceStartedAt.split('T')[0]}
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-submittedat"
                              >
                                Ημερομηνία Τελευταίας Τροποποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instanceSubmittedAt.split('T')[0]}
                                id="input-submittedat"
                                placeholder="Ημερομηνία Τελευταίας Τροποποίησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        {/* <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Αυτοψιας
                        </h6> */}
                        <div className="pl-lg-4">
                          <Row>

                            {Object.entries(this.state.dict1)
                              .map(([key, value]) =>
                                <>


                                  {key != 'Υπόδειγμα Βεβαίωσης Αυτοψίας' && key != 'Εκτός Σχεδίου Απάντηση' && key != 'Αίτηση' && key != 'Θεωρημένη μελέτη/σχέδια' && key != 'Φωτογραφίες δικτύου' ?
                                    <>
                                      <Col lg="3">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                          >
                                            {key}
                                          </label>
                                          <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            defaultValue={value}
                                            placeholder={key}
                                            type="text"
                                            disabled
                                          />
                                        </FormGroup>
                                      </Col>
                                    </> : <></>
                                  }


                                </>)}
                          </Row>

                        </div>
                      </div>


                      <hr className="my-4" />
                      {/* {this.SignedDocuments.renderHeader()}
                      {this.SignedDocuments.renderDetails()} */}
                       {this.SignedDocuments.render()}                      
                      

                      <hr className="my-4" />
                      {this.state.instanceStatus == 3 ?
                        <>

                          <div className="pl-lg-4">
                            {this.state.user.PersonRole != "User" ?
                              <h6 className="heading-small text-muted mb-4">
                                ΕΝΕΡΓΕΙΕΣ ΑΥΤΟΨΙΑΣ
                              </h6>
                              :
                              <>
                              </>
                            }

                            <div className="pl-lg-4">
                              <Row>

                                {this.state.user.PersonRole != "User" ?
                                  <>
                                    <Col lg="3">

                                      <Button
                                        color="primary"
                                        href={"/newlyBuilt/ypodeigma/" + this.props.match.params.id + '/' + 3 + '/'}
                                        size="sm"
                                        style={{ marginTop: '40px' }}
                                        target="_blank"
                                      >
                                        Εντός Σχεδίου Απάντηση

                                      </Button>
                                    </Col>
                                    <Col lg="3">

                                      <Button
                                        color="primary"
                                        href={"/newlyBuilt/ektossxediou/" + this.props.match.params.id + '/' + 3 + '/'}
                                        size="sm"
                                        style={{ marginTop: '40px' }}
                                      >
                                        Εκτός Σχεδίου Απάντηση

                                      </Button>

                                    </Col>
                                    <Col lg="3">

                                      <Button
                                        color="danger"
                                        onClick={e => { this.emailMechanic(e) }}
                                        size="sm"
                                        style={{ marginTop: '40px' }}
                                      >
                                        Ενημέρωση Μελετητή

                                      </Button>

                                    </Col>

                                    {/* <Col lg="2">
                                      <Button
                                        color="danger"
                                        href="#"
                                        onClick={() => { window.location.replace("/newlyBuilt/addcomment/" + this.props.match.params.id) }}
                                        size="sm"
                                        style={{ marginTop: '40px', marginLeft: '-45px' }}
                                      >
                                        Επανεξέταση
                                      </Button>
                                    </Col> */}
                                  </> : <></>
                                }


                              </Row>

                            </div>
                          </div>
                        </> : <></>
                      }
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

  }
}

export default NewlyBuiltAutopsy;
