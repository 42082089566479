import Login from "views/examples/Login.js";
import Logout from "views/examples/Logout.js";
import NewlyBuiltIndex from "views/examples/newlyBuilt/newlyBuiltIndex.js";
import NewResearch from "views/examples/newlyBuilt/newResearch.js";
import NewResult from "views/examples/newlyBuilt/newResult";
import newAutopsy from "views/examples/newlyBuilt/newAutopsy.js";
import NewlyBuiltDetails from "views/examples/newlyBuilt/newlyBuiltDetails.js"
import NewlyBuiltInstance from "views/examples/newlyBuilt/newlyBuiltInstance.js";
import NewlyBuiltAutopsy from "views/examples/newlyBuilt/newlyBuiltAutopsy.js";
import NewlyBuiltNewResearches from "views/examples/newlyBuilt/newlyBuiltNewResearches.js";
import NewlyBuiltNotCompletedInstances from "views/examples/newlyBuilt/newlyBuiltNotCompleted.js";
import NewlyBuiltAutopsiesNotCompleted from "views/examples/newlyBuilt/newlyBuiltAutopsiesNotCompleted.js";
import NewlyBuiltInstances from "views/examples/newlyBuilt/newlyBuiltInstances.js";
import NewlyBuiltAutopsies from "views/examples/newlyBuilt/newlyBuiltAutopsies.js";
import NewlyBuiltDocuments from "views/examples/newlyBuilt/newlyBuiltDocuments.js";
import NewComment from "views/examples/newlyBuilt/newComment";
import NewlyBuiltComments from "views/examples/newlyBuilt/newlyBuiltComments.js";
import InstanceDocuments from "views/examples/newlyBuilt/instanceDocuments";
import NewlyBuiltProfile from "views/examples/newlyBuilt/newlyBuiltProfile.js";
import NewlyBuiltSettings from "views/examples/newlyBuilt/newlyBuiltSettings.js";
import SignUp from "views/examples/signup.js";
import YpodeigmaVevaiosis from "views/examples/newlyBuilt/ypodeigma.js";
import EktosSxediou from "views/examples/newlyBuilt/ektossxediou";
import formVisa from "views/examples/newlyBuilt/formVisa.js";
import Assign from "views/examples/newlyBuilt/Assign.js";

var routes = [
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltIndex,
    layout: "/newlyBuilt"
  },
  {
    path: "/newresearch",
    name: "Κατοχύρωση Νέας",
    icon: "ni ni-circle-08 text-pink",
    component: NewResearch,
    layout: "/newlyBuilt"
  },
  {
    path: "/assign",
    name: "Ανάθεση σε Εξωτερικό Συνεργάτη",
    icon: "ni ni-circle-08 text-pink",
    component: Assign,
    layout: "/newlyBuilt"
  },
  {
    path: "/newAutopsy",
    name: "Κατοχύρωση Νέας",
    icon: "ni ni-circle-08 text-pink",
    component: newAutopsy,
    layout: "/newlyBuilt"
  },
  {
    path: "/result/:id",
    name: "Αποτέλεσμα Κατοχύρωσης",
    icon: "ni ni-circle-08 text-pink",
    component: NewResult,
    layout: "/newlyBuilt"
  },
  {
    path: "/instances",
    name: "Αιτήματα",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltInstances,
    layout: "/newlyBuilt"
  },
  {
    path: "/uncompletedinstances",
    name: "Μη Ολοκληρωμένα Αιτήματα",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltNotCompletedInstances,
    layout: "/newlyBuilt"
  },
  {
    path: "/uncompletedautopsies",
    name: "Μη Ολοκληρωμένες Αυτοψίες",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltAutopsiesNotCompleted,
    layout: "/newlyBuilt"
  },
  {
    path: "/autopsies",
    name: "Ολοκληρωμένες Αυτοψίες",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltAutopsies,
    layout: "/newlyBuilt"
  },
  {
    path: "/instance/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltInstance,
    layout: "/newlyBuilt"
  },
  {
    path: "/autopsy/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltAutopsy,
    layout: "/newlyBuilt"
  },
  {
    path: "/details/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltDetails,
    layout: "/newlyBuilt"
  },
  {
    path: "/files/:id",
    name: "Αιτήματα",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceDocuments,
    layout: "/newlyBuilt"
  },
  {
    path: "/addcomment/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: NewComment,
    layout: "/newlyBuilt"
  },
  {
    path: "/comments/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltComments,
    layout: "/newlyBuilt"
  },
  {
    path: "/documents",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltDocuments,
    layout: "/newlyBuilt"
  },
  {
    path: "/profile",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltProfile,
    layout: "/newlyBuilt"
  },
  {
    path: "/settings",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltSettings,
    layout: "/newlyBuilt"
  },
  {
    path: "/ypodeigma",
    name: "Υπόδειγμα Βεβαίωσης",
    icon: "ni ni-circle-08 text-pink",
    component: YpodeigmaVevaiosis,
    layout: "/newlyBuilt"
  },
  {
    path: "/formvisa",
    name: "Φόρμα Θεώρησης",
    icon: "ni ni-circle-08 text-pink",
    component: formVisa,
    layout: "/newlyBuilt"
  },
  {
    path: "/ektossxediou",
    name: "Εκτός Σχεδίου Απάντηση",
    icon: "ni ni-circle-08 text-pink",
    component: EktosSxediou,
    layout: "/newlyBuilt"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/auth"
  },
  {
    path: "/signup",
    name: "SignUp",
    icon: "ni ni-key-25 text-info",
    component: SignUp,
    layout: "/auth"
  },
  {
    path: "/newlyBuiltNewResearches",
    name: "Νέες Μελέτες",
    icon: "ni ni-circle-08 text-pink",
    component: NewlyBuiltNewResearches,
    layout: "/newlyBuilt"
  },
];

export default routes;
