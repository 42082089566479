import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import axios from 'axios';

import Moment from "moment";
import 'moment/locale/el';

import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select'

import 'antd/dist/antd.css';

import { Cascader } from 'antd';

import {WorkflowHelper} from '../../../common/workflowHelper';

const options = [
  // { value: 'default', label: 'Επιλέξε' },
  { value: 'neaoikodomi', label: 'Νέα Οικοδομή' },
  { value: 'anatheorisi', label: 'Αναθεώρηση' },
]

const ektos_sxediou_options = [
  // { value: 'default', label: 'Επιλέξε' },
  { value: 'ΝΑΙ', label: 'ΝΑΙ' },
  { value: 'ΟΧΙ', label: 'ΟΧΙ' },
]

const poleodomia_options = [
  // { value: 'default', label: 'Επιλέξε' },
  {value: 'ΔΗΜΟΣ ΒΕΡΟΙΑΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΒΕΡΟΙΑΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΣΕΡΡΩΝ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΣΕΡΡΩΝ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΠΑΓΓΑΙΟΥ- ΥΔΟΜ', label: 'ΔΗΜΟΣ ΠΑΓΓΑΙΟΥ- ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΘΗΒΑΣ- ΥΔΟΜ', label: 'ΔΗΜΟΣ ΘΗΒΑΣ- ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΛΕΒΑΔΕΩΝ- ΥΔΟΜ', label: 'ΔΗΜΟΣ ΛΕΒΑΔΕΩΝ- ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΔΡΑΜΑΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΔΡΑΜΑΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΚΟΜΟΤΗΝΗΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΚΟΜΟΤΗΝΗΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΧΑΛΚΙΔΕΩΝ -ΥΔΟΜ', label: 'ΔΗΜΟΣ ΧΑΛΚΙΔΕΩΝ -ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΚΑΤΕΡΙΝΗΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΚΑΤΕΡΙΝΗΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΔΕΛΦΩΝ -ΥΔΟΜ', label: 'ΔΗΜΟΣ ΔΕΛΦΩΝ -ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΞΑΝΘΗΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΞΑΝΘΗΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΑΛΕΞ/ΠΟΛΗΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΑΛΕΞ/ΠΟΛΗΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΚΙΛΚΙΣ – ΥΔΟΜ', label: 'ΔΗΜΟΣ ΚΙΛΚΙΣ – ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΝΑΟΥΣΑΣ -ΥΔΟΜ', label: 'ΔΗΜΟΣ ΝΑΟΥΣΑΣ -ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΚΑΒΑΛΑΣ-ΥΔΟΜ', label: 'ΔΗΜΟΣ ΚΑΒΑΛΑΣ-ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΠΕΛΛΑΣ- ΥΔΟΜ', label: 'ΔΗΜΟΣ ΠΕΛΛΑΣ- ΥΔΟΜ'},
  {value: 'ΔΗΜΟΣ ΛΑΜΙΑΣ ΔΙΕΥΘΥΝΣΗ ΥΠΗΡΕΣΙΑΣ ΔΟΜΗΣΗΣ', label: 'ΔΗΜΟΣ ΛΑΜΙΑΣ ΔΙΕΥΘΥΝΣΗ ΥΠΗΡΕΣΙΑΣ ΔΟΜΗΣΗΣ'},
  {value: 'ΔΗΜΟΣ ΕΔΕΣΣΑΣ - ΠΟΛΕΟΔΟΜΙΚΟ ΓΡΑΦΕΙΟ ΣΚΥΔΡΑ', label: 'ΔΗΜΟΣ ΕΔΕΣΣΑΣ - ΠΟΛΕΟΔΟΜΙΚΟ ΓΡΑΦΕΙΟ ΣΚΥΔΡΑ'},
  {value: 'ΔΗΜΟΣ ΑΛΕΞΑΝΔΡΕΙΑΣ - ΥΠΗΡΕΣΙΑ ΔΟΜΗΣΗΣ - ΓΡΑΦΕΙΟ ΟΙΚΟΔΟΜΙΚΩΝ ΑΔΕΙΩΝ', label: 'ΔΗΜΟΣ ΑΛΕΞΑΝΔΡΕΙΑΣ - ΥΠΗΡΕΣΙΑ ΔΟΜΗΣΗΣ - ΓΡΑΦΕΙΟ ΟΙΚΟΔΟΜΙΚΩΝ ΑΔΕΙΩΝ'},
  {value: 'ΔΗΜΟΣ ΠΑΙΟΝΙΑΣ - ΔΗΜΟΤΙΚΗ ΕΝΟΤΗΤΑ ΠΟΛΥΚΑΣΤΡΟΥ - ΤΜΗΜΑ ΠΟΛΕΟΔΟΜΙΑΣ ΔΗΜΟΣ ΑΞΙΟΥΠΟΛΗΣ', label: 'ΔΗΜΟΣ ΠΑΙΟΝΙΑΣ - ΔΗΜΟΤΙΚΗ ΕΝΟΤΗΤΑ ΠΟΛΥΚΑΣΤΡΟΥ - ΤΜΗΜΑ ΠΟΛΕΟΔΟΜΙΑΣ ΔΗΜΟΣ ΑΞΙΟΥΠΟΛΗΣ'}
]



// console.log(JSON.parse(dimoi_nomoi))

// import ReactTooltip from 'react-tooltip';

class NewResearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      instanceId: '',
      steps: [],
      files: [],
      fileUpload: null,
      showAnatheorisi: false
    };

    this.deleteFile = this.deleteFile.bind(this);
  }

  async componentDidMount() {
    // document.getElementById('submit-button-1').setAttribute('disabled', '');
    this.setState({
      instanceId: uuidv4()
    })

    axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/initiliaze/7f4445d1-841a-4fda-8809-6225ceabd2c5/", {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(res => {
        this.setState({
          steps: res.data.stepsInfused
        });
        try {
          document.getElementById('submit-button-1').setAttribute('disabled', '');
        }
        catch {

        }
      })
  }

  deleteInstance = stepStatus => {
    window.location.replace("/consumer/index");
  }

  deleteFile = fileId => {
    if (localStorage.getItem('token')) {
      axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          axios.get(process.env.REACT_APP_API_LINK + "/api/files/initializestatus/" + this.state.instanceId + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
          })
            .then(res => {
              this.setState({
                loading: false,
                files: res.data
              });
              // //console.log(res);
            })
        })
    }
  }

  uploadFile(evt, actionid) {
    const formData = new FormData();
    var res = actionid.split("/");

    if (evt.target.files.length > 1) {
      var self = this;

      for (let i = 0; i < evt.target.files.length; i++) {
        let file = evt.target.files.item(i);

        const newformData = new FormData();
        newformData.append(
          "files",
          file
        );

        fetch(
          'https://filemanager.deda.gr/',
          {
            method: 'POST',
            body: newformData,
          }
        )
          .then((response) => response.json())
          .then((result) => {
            // //console.log('Success:', result);

            fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
              method: 'POST',
              headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
              }),
              body: JSON.stringify({
                'Name': result[0].name,
                'Path': result[0].path,
                'Size': result[0].size,
                'Type': result[0].type,
                'InstanceId': self.state.instanceId,
                'StepId': res[0],
                'ActionId': res[1]
              })
            })
              .then(data => {
                // //console.log(data);

                axios.get(process.env.REACT_APP_API_LINK + "/api/files/initializestatus/" + self.state.instanceId + "/", {
                  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                  .then(res => {
                    self.setState({
                      loading: false,
                      files: res.data
                    });
                  })
              })
          })
          .catch((error) => {
            console.error('Error:', error);
          });


      }



    }
    else {
      // Update the formData object 
      formData.append(
        "files",
        evt.target.files[0]
      );

      // Details of the uploaded file 
      // //console.log(evt.target.files[0]);
      // //console.log(formData);


      fetch(
        'https://filemanager.deda.gr/',
        {
          method: 'POST',
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // //console.log('Success:', result);

          fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
            method: 'POST',
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
              'Name': result[0].name,
              'Path': result[0].path,
              'Size': result[0].size,
              'Type': result[0].type,
              'InstanceId': this.state.instanceId,
              'StepId': res[0],
              'ActionId': res[1]
            })
          })
            .then(data => {
              // //console.log(data);

              axios.get(process.env.REACT_APP_API_LINK + "/api/files/initializestatus/" + this.state.instanceId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
              })
                .then(res => {
                  this.setState({
                    loading: false,
                    files: res.data
                  });
                })
            })
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

  }

  cascaderonChange = (id, evt) => {

    var location;
    // console.log(Object.keys(evt).length);
    if (evt) {

      const nomos = evt[0];
      const dimos = evt[1];

      location = nomos + ' / ' + dimos;
    }

    else {
      location = '';
    }
    console.log(location);

    var res = id.split("/");
    let data = [...this.state.steps];


    var i;
    for (i = 0; i < (this.state.steps).length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < (this.state.steps[i].actions).length; j++) {
          let tempAction = { ...data[i].actions[j] };
          if (tempAction.action.Type == 'dropdown' && tempAction.action.Name == 'Νομός/Δήμος') {

            tempAction.data.Data = location;
            data[i].actions[j] = tempAction;
          }
        }
      }

    }


  }

  updateInputValue(evt) {
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    function checkifFilled(item) {
      return item.data.Data !== '';
    }
    // //console.log(res);

    var i;
    for (i = 0; i < (this.state.steps).length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        let checkAction = this.state.steps[i].actions.slice(0, 9)
        let checkRequired = checkAction.filter(element => {
          if (element.action.Required) return element
        })
        var j;
        for (j = 0; j < (this.state.steps[i].actions).length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data
            });
          }
        }
        // checkRequired.forEach(element => {
        //   if (element.data.Data == '') {
        //     document.getElementById('submit-button-1').setAttribute('disabled', '');
        //     // document.getElementById('submit-button-2').setAttribute('disabled', '');
        //   }
        // })
        // if (checkRequired.every(checkifFilled)) {
        //   document.getElementById('submit-button-1').removeAttribute('disabled');
        //   // document.getElementById('submit-button-2').removeAttribute('disabled');
        // }
      }
    }
  }

  updateInputValueNumber(evt, dynamicactions) {

    if (dynamicactions.action.Target == 'limit14') {
      if ((evt.target.value).length > 14) {
        evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
      }
    }

    if (dynamicactions.action.Target == 'limit9') {
      if ((evt.target.value).length > 9) {
        evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
      }
    }

    var res = evt.target.id.split("/");
    let data = [...this.state.steps];
    function checkifFilled(item) {
      return item.data.Data !== '';
    }
    // //console.log(res);

    var i;
    for (i = 0; i < (this.state.steps).length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        let checkAction = this.state.steps[i].actions.slice(0, 9)
        let checkRequired = checkAction.filter(element => {
          if (element.action.Required && element.action.Type == 'text' && element.action.Name != 'Αριθμός Προηγούμενης Μελέτης') return element
        })
        var j;
        for (j = 0; j < (this.state.steps[i].actions).length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data
            });
          }
        }
        checkRequired.forEach(element => {
          if (element.data.Data == '' && element.action.Required) {
            document.getElementById('submit-button-1').setAttribute('disabled', '');
            // document.getElementById('submit-button-2').setAttribute('disabled', '');
          }
        })
        if (checkRequired.every(checkifFilled)) {
          document.getElementById('submit-button-1').removeAttribute('disabled');
          // document.getElementById('submit-button-2').removeAttribute('disabled');
        }
      }
    }
  }

  submitInstance = stepStatus => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/new/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'workflow': '7f4445d1-841a-4fda-8809-6225ceabd2c5',
          'instanceId': this.state.instanceId
        })
      })
        .then(res => res.json())
        .then(data => {
          fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/submit/' + data.Id + '/', {
            method: 'POST',
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify(this.state.steps[stepStatus - 1])
          })
            .then(data => {
              window.location.replace("/newlyBuilt/instance/" + this.state.instanceId);
            })
        })
    }
  }

  selectChange = (evt, id) => {
    var res = id.split("/");
    let data = [...this.state.steps];


    var i;
    for (i = 0; i < (this.state.steps).length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < (this.state.steps[i].actions).length; j++) {
          let tempAction = { ...data[i].actions[j] };
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            if (tempAction.action.Type == 'dropdown' && tempAction.action.Name != 'Νομός/Δήμος') {
              if (tempAction.action.Name != 'Εκτός Σχεδίου') {

                document.getElementById('submit-button-1').removeAttribute('disabled');
              }
              console.log(evt.label);
              tempAction.data.Data = evt.label;
              data[i].actions[j] = tempAction;
            }
          }

        }
      }

    }

    if (evt.value == 'anatheorisi') {
      this.setState({
        showAnatheorisi: true
      })
    }
    else {
      this.setState({
        showAnatheorisi: false
      })
    }

    //console.log(id);
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <>
            {this.state.steps.map((dynamicData) =>
              <>
                {dynamicData.step.Status == 1 ?
                  <>
                    {dynamicData.step.Conditional ?
                      <Container className="mt-2" fluid>
                        <Row className="mt-5">
                          <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                              <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                  <Col xs="8">
                                    <h3 className="mb-0">{dynamicData.step.Name}</h3>
                                  </Col>
                                  <div className="col text-right">
                                    <Button color="primary" type="button" href="https://deda.gr/wp-content/uploads/2022/04/%CE%9F%CE%94%CE%97%CE%93%CE%99%CE%95%CE%A3-%CE%A0%CE%A1%CE%9F%CE%A3-%CE%9C%CE%97%CE%A7%CE%91%CE%9D%CE%99%CE%9A%CE%9F%CE%A5%CE%A3-%CE%93%CE%99%CE%91-%CE%A4%CE%97%CE%9D-%CE%A5%CE%A0%CE%9F%CE%92%CE%9F%CE%9B%CE%9B%CE%97-%CE%9C%CE%95%CE%9B%CE%95%CE%A4%CE%A9%CE%9D-%CE%9A%CE%91%CE%99-%CE%91%CE%A5%CE%A4%CE%9F%CE%A8%CE%99%CE%A9%CE%9D.docx">
                                      <span>ΟΔΗΓΙΕΣ ΠΡΟΣ ΜΗΧΑΝΙΚΟΥΣ</span>
                                    </Button>
                                  </div>
                                  {/* <Col className="text-right" xs="4">
                                    <Button
                                      color="danger"
                                      onClick={() => { this.deleteInstance() }}
                                      size="sm"
                                    >
                                      Ακύρωση
                                    </Button>
                                    <Button
                                      color="success"
                                      onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                      size="sm"
                                      id="submit-button-1"
                                    >
                                      Υποβολή
                                    </Button>
                                  </Col> */}
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <span style={{ marginLeft: '20px' }}><b>Οδηγία: </b>Τα πεδία να συμπληρώνονται με κεφαλαία γράμματα</span> <br /> <br />
                                <Form>
                                  <div className="pl-lg-4">
                                    <Row>
                                      {this.state.enabled = dynamicData.step.Status === 1, dynamicData.actions.map((dynamicActions) =>
                                        <>
                                          {dynamicActions.action.RunTime == 'user' ?
                                            <Col lg="12">
                                              <FormGroup>

                                                {dynamicActions.action.Name != 'Αριθμός Προηγούμενης Μελέτης' && dynamicActions.action.Name != 'Θεωρημένη Μελέτη' ?
                                                  <>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="input-username"
                                                    >
                                                      {dynamicActions.action.Name}
                                                    </label>
                                                  </>
                                                  :
                                                  <>
                                                    {this.state.showAnatheorisi && dynamicActions.action.Name != 'Θεωρημένη Μελέτη' ?
                                                      <>
                                                        <label
                                                          className="form-control-label"
                                                          htmlFor="input-username"
                                                        >
                                                          {dynamicActions.action.Name}
                                                        </label>
                                                      </> : <></>
                                                    }
                                                  </>
                                                }

                                                {dynamicActions.action.Type == 'file' ?
                                                  <>
                                                    {dynamicActions.action.Name != 'Θεωρημένη Μελέτη' ?
                                                      <>
                                                        <Row>
                                                          <Col lg="10">

                                                            <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
                                                              Επιλογή αρχείου
                                                            </label>
                                                            &nbsp;
                                                            {/* <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i> */}
                                                            {/* <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                                                                <div id="imageattooltip"></div>
                                                            </ReactTooltip> */}
                                                            <Input
                                                              className="form-control-alternative"
                                                              // defaultValue={ dynamicActions.data.Data }
                                                              id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                              // placeholder={dynamicActions.action.Description}
                                                              style={{ visibility: "hidden" }}
                                                              type={dynamicActions.action.Type}
                                                              // disabled={!dynamicActions.action.Required}
                                                              onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                              accept="application/pdf"
                                                              multiple
                                                            />
                                                          </Col>
                                                        </Row>

                                                        <Table className="align-items-center table-flush" responsive>
                                                          <thead className="thead-light">
                                                            <tr>
                                                              <th scope="col">Ονομα Αρχειου</th>
                                                              <th scope="col">Ημερομηνια Αναρτησης</th>
                                                              <th scope="col">Ενεργειες</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {this.state.files.map((dynamicFiles) =>
                                                              <>
                                                                {dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                  <>
                                                                    <tr>
                                                                      <td>{dynamicFiles.FileName}</td>
                                                                      <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                                                      <td>
                                                                        <Button
                                                                          color="primary"
                                                                          href={dynamicFiles.Link}
                                                                          target="_blank"
                                                                          size="sm"
                                                                          title="Προβολή"
                                                                        >
                                                                          <i className="fas fa-eye" />
                                                                        </Button>
                                                                        <Button
                                                                          color="danger"
                                                                          onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                          size="sm"
                                                                          title="Διαγραφή"
                                                                        >
                                                                          <i className="fas fa-trash" />
                                                                        </Button>
                                                                      </td>
                                                                    </tr>
                                                                  </> : <></>
                                                                }
                                                              </>
                                                            )}
                                                          </tbody>
                                                        </Table>
                                                      </> : <></>
                                                    }

                                                  </>
                                                  : <> {dynamicActions.action.Type == 'number' ?
                                                    <React.Fragment>
                                                      {/* <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i> */}
                                                      <Input
                                                        className="form-control-alternative"
                                                        defaultValue={dynamicActions.data.Data}
                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                        placeholder={dynamicActions.action.Description}
                                                        type={dynamicActions.action.Type}
                                                        disabled={!dynamicActions.action.Required}
                                                        onChange={evt => this.updateInputValueNumber(evt, dynamicActions)}

                                                      />
                                                    </React.Fragment>
                                                    : <React.Fragment>
                                                      {/* <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i> */}
                                                      {dynamicActions.action.Type == 'dropdown' ?
                                                        <>
                                                          {dynamicActions.action.Name == 'Πολεοδομία' ?

                                                            <Select options={poleodomia_options} defaultValue={{ label: "Επιλογή Πολεοδομίας", value: 0 }} onChange={e => { this.selectChange(e, (dynamicData.step.Id + "/" + dynamicActions.action.Id)) }} />
                                                            : <></>
                                                          }
                                                          {dynamicActions.action.Name == 'Νέα Οικοδομή ή Αναθεώρηση' ?

                                                            <Select options={options} defaultValue={{ label: "Επιλέξτε αν πρόκειται για Νέα Οικοδομή ή Αναθεώρηση", value: 0 }} onChange={e => { this.selectChange(e, (dynamicData.step.Id + "/" + dynamicActions.action.Id)) }} />
                                                            : <></>
                                                          }
                                                          {dynamicActions.action.Name == 'Εκτός Σχεδίου' ?

                                                            <Select options={ektos_sxediou_options} defaultValue={{ label: "Παρακαλούμε επιλέξτε ΝΑΙ/ΟΧΙ αν πρόκειται για εκτός σχεδίου ή όχι αντίστοιχα", value: 0 }} onChange={e => { this.selectChange(e, (dynamicData.step.Id + "/" + dynamicActions.action.Id)) }} />
                                                            : <></>
                                                          }
                                                          {dynamicActions.action.Name == 'Νομός/Δήμος' ?

                                                            <>
                                                              <br />
                                                              <Cascader
                                                                style={{ width: '100%' }}
                                                                className={'dropdown'}
                                                                placeholder="Επιλέξτε Νομό και Δήμο"
                                                                // menuWidth={400}
                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                onChange={(e) => { this.cascaderonChange((dynamicData.step.Id + "/" + dynamicActions.action.Id), e) }}
                                                                options={JSON.parse(dynamicActions.action.Target)}
                                                              />
                                                            </>
                                                            : <></>
                                                          }

                                                        </>
                                                        :
                                                        <>
                                                          {dynamicActions.action.Name != 'Αριθμός Προηγούμενης Μελέτης' && dynamicActions.action.Name != 'Σχόλιο προς Μηχανικό' ?
                                                            <>
                                                              <Input
                                                                className="form-control-alternative"
                                                                defaultValue={dynamicActions.data.Data}
                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                placeholder={dynamicActions.action.Description}
                                                                type={dynamicActions.action.Type}
                                                                disabled={!dynamicActions.action.Required}
                                                                onChange={evt => this.updateInputValue(evt)}

                                                              />
                                                            </>
                                                            :
                                                            <>

                                                              {this.state.showAnatheorisi && dynamicActions.action.Name != 'Σχόλιο προς Μηχανικό' ?
                                                                <>
                                                                  <Input
                                                                    className="form-control-alternative"
                                                                    // defaultValue={'-'}
                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                    placeholder={'Συμπληρώστε τον Αριθμό της προηγούμενης Μελέτης'}
                                                                    type={dynamicActions.action.Type}
                                                                    disabled={!dynamicActions.action.Required}
                                                                    onChange={evt => this.updateInputValue(evt)}

                                                                  />
                                                                </> : <></>
                                                              }


                                                            </>
                                                          }

                                                        </>
                                                      }

                                                    </React.Fragment>
                                                  }
                                                  </>
                                                }
                                              </FormGroup>
                                            </Col>
                                            :
                                            <> </>
                                          }
                                        </>
                                      )}
                                    </Row>
                                  </div>
                                </Form>
                              </CardBody>
                              <CardFooter className="bg-white border-0">
                                <Row className="align-items-center">
                                  <Col xs="8">
                                    {/* <h3 className="mb-0">{dynamicData.step.Name}</h3> */}
                                  </Col>
                                  <Col className="text-right" xs="4">
                                    <Button
                                      color="danger"
                                      onClick={() => { this.deleteInstance() }}
                                      size="sm"
                                    >
                                      Ακύρωση
                                    </Button>
                                    <Button
                                      color="success"
                                      onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                      size="sm"
                                      id="submit-button-1"
                                    >
                                      Υποβολή
                                    </Button>
                                  </Col>
                                </Row>
                              </CardFooter>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                      : <Container className="mt-2" fluid>
                        <Row className="mt-5">
                          <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                              <CardBody>
                                <Form>
                                  <div className="pl-lg-4">
                                    <Row>
                                      <Col lg="12">
                                        <p style={{ fontSize: '18px' }}>Θα ενημερωθείτε σύντομα για την εξέλιξη του αιτήματός σας.</p>
                                        <Button
                                          color="primary"
                                          href={                                                                                    
                                            WorkflowHelper.isWorkflowIdTypeNewlyBuildStudyFromInstance(this.state.instance)?
                                            "/newlyBuilt/details/" : "/newlyBuilt/autopsy/" 
                                             + this.state.instance.Id}
                                          size="lg"
                                        >
                                          Στοιχεία Αιτήματος
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Form>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    }
                  </>
                  :
                  <> </>
                }
              </>
            )}
          </>
        </Container>
      </>
    );
  }
}

export default NewResearch;
