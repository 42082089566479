import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';
import axios from 'axios';

class NewlyBuiltDocuments extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      files: []
    };
  }

  async componentDidMount() {
    const handle  = this.props.match.params.id;
    //console.log(handle);

    await axios.get(process.env.REACT_APP_API_LINK + "/api/newlyBuilts/allfiles/" + handle + "/", {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
    .then(res => {
      this.setState({
          loading: false,
          files: res.data
      });
      //console.log(res);
    })
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Πρότυπα Έγγραφα και Πληροφορίες</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="8" xl="2"> 
                      
                    </Col>
                    
                    {/* <Col lg="8" xl="4"> 
                      <Card className="card-stats mb-4 mb-xl-0" onClick={() => window.open("https://deda.gr/wp-content/uploads/2021/12/%CE%A6%CE%AC%CE%BA%CE%B5%CE%BB%CE%BF%CF%82-%CE%9C%CE%B5%CE%BB%CE%AD%CF%84%CE%B7%CF%82-.pdf", "_blank")}>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Φακελος Μελετης 
                              </CardTitle>
                              <span className="h5 mb-0">
                                Αφορά στο τι πρέπει να περιέχει ένας φάκελος μελέτης. <br/><br/>
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-folder-open" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                  <Row>
                      <Col lg="8" xl="2"> 
                        <br />
                      </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2"> 
                      
                    </Col>
                    <Col lg="8" xl="4"> 
                      <Card className="card-stats mb-4 mb-xl-0" onClick={() => window.open("https://deda.gr/wp-content/uploads/2021/12/1.-%CE%9C%CE%95%CE%9B%CE%95%CE%A4%CE%97.pdf", "_blank")}>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Προτυπο τευχος μελετης - τεχνικης εκθεσης
                              </CardTitle>
                              <span className="h5 mb-0">
                                
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-pdf" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="8" xl="4"> 
                      <Card className="card-stats mb-4 mb-xl-0" onClick={() => window.open("https://deda.gr/wp-content/uploads/2021/12/%CE%94%CE%B9%CE%B5%CF%85%CE%BA%CF%81%CE%B9%CE%BD%CE%B7%CF%83%CE%B5%CE%B9%CF%82_%CE%A0%CE%B1%CF%81%CE%B1%CF%84%CE%B7%CF%81%CE%AE%CF%83%CE%B5%CE%B9%CF%821.pdf", "_blank")}>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Διευκρινισεις - Παρατηρησεις
                              </CardTitle>
                              <span className="h5 mb-0">
                              Οι συνηθέστερες παρατηρήσεις που μπορεί να βγουν κατά την θεώρηση μιας μελέτης.
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-question" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                      <Col lg="8" xl="2"> 
                        <br />
                      </Col>
                  </Row>
                  <Row>
                    <Col lg="8" xl="2"> 
                      
                    </Col>
                    <Col lg="8" xl="4"> 
                      <Card className="card-stats mb-4 mb-xl-0" onClick={() => window.open("https://deda.gr/wp-content/uploads/2022/04/%CE%91%CE%99%CE%A4%CE%97%CE%9C%CE%91-%CE%94%CE%99%CE%95%CE%9D%CE%95%CE%A1%CE%93%CE%95%CE%99%CE%91%CE%A3-%CE%91%CE%A5%CE%A4%CE%9F%CE%A8%CE%99%CE%91%CE%A3-%CE%95%CE%95%CE%9D%CE%9F.doc", "_blank")}>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ΑΙΤΗΜΑ ΔΙΕΝΕΡΓΕΙΑΣ ΑΥΤΟΨΙΑΣ ΕΕΝΟ
                              </CardTitle>
                              <span className="h5 mb-0">
                                
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-pdf" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="8" xl="4"> 
                      <Card className="card-stats mb-4 mb-xl-0" onClick={() => window.open('https://deda.gr/wp-content/uploads/2022/04/%CE%91%CE%99%CE%A4%CE%97%CE%A3%CE%97-%CE%95%CE%9A%CE%A4%CE%9F%CE%A3-%CE%A3%CE%A7%CE%95%CE%94%CE%99%CE%9F%CE%A5-%CE%95%CE%95%CE%9D%CE%9F.doc', "_blank") }>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                               ΑΙΤΗΣΗ ΕΚΤΟΣ ΣΧΕΔΙΟΥ ΕΕΝΟ
                              </CardTitle>
                              <span className="h5 mb-0">
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                <i className="fas fa-file-word" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewlyBuiltDocuments;
