import {
    get,
    post,
    del,
    getAuthorizationHeader,
    hasInitialisedToken,
} from "./apiCallerHelper.core";

export const ApiCallerHelperFiles = {
    postFilesNew: postFilesNew,
    deleteFileById: deleteFileById,
    getAllFilesByWorkflowInstanceId: getAllFilesByWorkflowInstanceId,
    getAllFilesByWorkflowInstanceId: getAllFilesByWorkflowInstanceId,
    getFileStatusByWorkflowInstanceId: getFileStatusByWorkflowInstanceId,
};

export function postFilesNew(data, successCallback) {
    var route = "/api/files/new/";
    post(route, data, successCallback);
}

export async function deleteFileById(id, successCallback) {
    var route = "/api/files/";
    await del(route, id, successCallback);
}

export async function getAllFilesByWorkflowInstanceId(workflowInstanceId, successCallback) {
    var route = "/api/files/initializestatus/" + workflowInstanceId;
    await get(route, successCallback);
}

export async function getFileStatusByWorkflowInstanceId(workflowInstanceId, successCallback) {
    var route = "/api/files/status/" + workflowInstanceId;
    await get(route, successCallback);
}