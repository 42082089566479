import {
  get,
  getAsync,
  post,
  del,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

export const ApiCallerHelper = {
  hasInitialisedToken: () => {
    return hasInitialisedToken();
  },
  Common: {
    get,
    post,
    delete: del,
  },
  Auth: {
    getUserAsync: getAuthUserAsync,
    getUser: getAuthUser,    
  },
  Workflowinstances: {
    getUncompletedResearchesAsync: getWorkflowinstancesUncompletedResearchesAsync,
    getUncompletedResearches: getWorkflowinstancesUncompletedResearches,    
    getNewResearchesAsync: getWorkflowinstancesNewResearchesAsync,
    getNewResearches: getWorkflowinstancesNewResearches,  
    getWorkflowinstancesById: getWorkflowinstancesById,
    getWorkflowinstancesByIdAsync: getWorkflowinstancesByIdAsync,     
    getWorkflowinstancesInternalCommentAsync : getWorkflowinstancesInternalCommentAsync,
    getWorkflowinstancesInternalComment: getWorkflowinstancesInternalComment,
    postWorkflowinstancesInternalComment: postWorkflowinstancesInternalComment
  },      
};

async function getAuthUserAsync() {
  var route = "/api/auth/user/";
  return getAsync(route);
}

async function getAuthUser(successCallback) {
  let res = await getAuthUserAsync();
  if (res) {
    successCallback(res);
  }
}

async function getWorkflowinstancesUncompletedResearchesAsync() {
  var route = "/api/workflowinstances/uncompletedResearches/";
  return getAsync(route);
}



async function getWorkflowinstancesUncompletedResearches(successCallback) {
  let res = await getWorkflowinstancesUncompletedResearches();
  if (res) {
    successCallback(res);
  }
}

async function getWorkflowinstancesNewResearchesAsync() {
  var route = "/api/workflowinstances/newResearch/";
  return getAsync(route);
}

async function getWorkflowinstancesNewResearches(successCallback) {
  let res = await getWorkflowinstancesNewResearchesAsync();
  if (res) {
    successCallback(res);
  }
}
async function getWorkflowinstancesByIdAsync(instanceId) {
  var route = `/api/workflowinstances/instance/${instanceId}/`;
  return getAsync(route);
}
async function getWorkflowinstancesById(instanceId, successCallback) {
  let res = await getWorkflowinstancesByIdAsync(instanceId);
  if (res) {
    successCallback(res);
  }
}

async function getWorkflowinstancesInternalComment(instanceId, successCallback) {
  let res = await getWorkflowinstancesInternalCommentAsync(instanceId);
  if (res) {
    successCallback(res);
  }
}

async function getWorkflowinstancesInternalCommentAsync(instanceId) {
  var route = "/api/workflowinstances/commentInternal/" + instanceId;
  return getAsync(route);
}

async function postWorkflowinstancesInternalComment(instanceId, comment, successCallback){
  var body = { Comment : comment};
  var route = "/api/workflowinstances/commentInternal/" + instanceId;
  return post(route, body, successCallback)
}