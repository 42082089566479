import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  Button
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import axios from 'axios';

import '../../../assets/css/fixed-comments.css';

class NewlyBuiltComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      comments: [],
      showModal: false,
      loading: true,
      instance: [],
      userDetails: []
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    //console.log(handle);

    fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then((result) => {
        //console.log(result);
        this.setState({
          userDetails: result
        });
      })

    const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instance/" + handle + "/", {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(res => {
        this.setState({
          instance: res.data.instance,
        });
        //console.log(res);
      })


    if (localStorage.getItem('token')) {
      const result1 = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/comments/" + handle + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          this.setState({
            step1comments: res.data.step1,
            step3comments: res.data.step3,
            loading: false
          });
        })
    };
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <InstanceHeader />
          <div className="header pb-4" style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}>
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Ιστορικό Σχολίων
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt--4" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardBody>
                    <div>
                      <div className="col text-right">
                        {this.state.instance.Status == 1 ?
                          <>
                            <Button
                              color="primary"
                              href={"/newlyBuilt/addcomment/" + this.props.match.params.id}
                              size="sm"
                            >
                              Προσθήκη Σχολίου

                            </Button>
                          </>
                          :
                          <></>
                        }

                      </div>
                      {this.state.instance.WorkflowId == '7f4445d1-841a-4fda-8809-6225ceabd2c5' ?
                        <>
                          <Col xs="8">
                            <h3 className="mb-0">{'Υποβολή Μελέτης'}</h3>
                            <p></p>
                          </Col>
                          <Table className="align-items-center table-flush" className="fixed-comments" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Χρηστης</th>
                                <th scope="col">Σχολιο</th>
                                <th scope="col">Ημερομηνια Δημιουργιας</th>
                                {/* <th scope="col">Πεδιο Βηματος</th> */}
                                {/* <th scope="col">Ενεργειες</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.step1comments.map((dynamicComment) =>
                                <tr>
                                  <td>{(this.state.userDetails.PersonRole == 'Admin' && dynamicComment.UserId == this.state.userDetails.Id) || (this.state.userDetails.PersonRole == 'User' && dynamicComment.UserId != this.state.userDetails.Id) ? 'Ελεγκτής' : 'Μελετητής'}</td>
                                  <td>{dynamicComment.Comment}</td>
                                  <td>{Moment(dynamicComment.CreatedAt).locale('el').format('dddd, DD MMM YYYY')}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          <hr />
                        </>
                        :
                        <>
                          <Col xs="8">
                            <h3 className="mb-0">{'Αυτοψία Κτιρίου'}</h3>
                            <p></p>
                          </Col>
                          <Table className="align-items-center table-flush" className="fixed-comments" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Χρηστης</th>
                                <th scope="col">Σχολιο</th>
                                <th scope="col">Ημερομηνια Δημιουργιας</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.step3comments.map((dynamicComment) =>
                                <tr>
                                  <td>{(this.state.userDetails.PersonRole == 'Admin' && dynamicComment.UserId == this.state.userDetails.Id) || (this.state.userDetails.PersonRole == 'User' && dynamicComment.UserId != this.state.userDetails.Id) ? 'Ελεγκτής' : 'Μελετητής'}</td>
                                  <td>{dynamicComment.Comment}</td>
                                  <td>{Moment(dynamicComment.CreatedAt).locale('el').format('dddd, DD MMM YYYY')}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          <hr />
                        </>
                      }




                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default NewlyBuiltComments;
