import React from "react";
import { CommonLoading } from 'react-loadingg';
import { LiteralsHelper } from "../../../common/literalsHelper";
import { UserRoleHelper } from "../../../common/userRoleHelper";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { WorkflowStepActionIdTypes } from "../../../common/enums/workflowStepActionIdTypes.enum";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import 'moment/locale/el';
import { MDBDataTable } from 'mdbreact';

const Literals = {
  Info : 'Στοιχεία',
  Actions : 'Ενέργειες',
  Notes : 'Σχόλια',
  Documents : 'Έγγραφα'

}

const InstanceStatusEnumType = {
  StudyRerun: "Μελέτη Υπό Επανεξέταση",
  StudySubmission: "Υποβολή Μελέτης",
  StudyApproved: "Θεωρημένη Μελέτη",  
}

const ColumnDefinitions = [
  {
    label: 'Αριθμος Αιτησης ▼',
    field: 'SerialNumber',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Κατασταση ▼',
    field: 'Status',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Ημ. Τελ. Ανανεωσης ▼',
    field: 'SubmittedAt',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Ανατεθηκε Σε ▼',
    field: 'EditedBy',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Εργοδοτης ▼',
    field: 'ergodotis',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Μηχανικος ▼',
    field: 'Name',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Πολη ▼',
    field: 'poli',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Μεταβαση ▼',
    field: 'Buttons',
    sort: 'asc',
    width: 150
  }
]

function GetInstanceStatusLiteral(item) {
  if (item.Declined == true){
    return InstanceStatusEnumType.StudyRerun;
  }

  if (item.Status == 1){
    return InstanceStatusEnumType.StudySubmission;
  }

  if (item.Status == 3){
    return InstanceStatusEnumType.StudyApproved;
  }

  return '';
}

class NewlyBuiltInstancesGenericViewByState extends React.Component {  
  constructor(props) {
    super(props);    
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      loading: true             
    };
  }
  
  __PageTitle = "" 

  setUserDetails = async (callbackAfterSetState) => {
    const result = await ApiCallerHelper.Auth.getUserAsync();
    this.setState({
      userDetails: result.data
    }, callbackAfterSetState);    
  }  
     
  generateGenericWorkflowInstancesGridDataAndSetState = (res) => {
    var isUserRoleUser = UserRoleHelper.isUser(this.state.userDetails);
    var isUserRoleAdmin = UserRoleHelper.isAdmin(this.state.userDetails);
    let result = res.data;      
      var instances = result[0];
      instances.forEach(function (part, index) {
        this[index].SubmittedAt = this[index].SubmittedAt.split("T")[0];
        this[index].Status = GetInstanceStatusLiteral(this[index]);
                    
        var allRows = result[1][this[index].Id];
        allRows.forEach(function (element, counter) {
          let value = element[2];
          var itemForUpdate = this[index];

          switch (element[1]) {
            case WorkflowStepActionIdTypes.ERGODOTIS:
              itemForUpdate.ergodotis = value;
              break;
            case WorkflowStepActionIdTypes.EPIVLEPON:
              itemForUpdate.mixanikos = value;
              break;
            case WorkflowStepActionIdTypes.NOMOS_DIMOS:
              itemForUpdate.poli = value;
              break;
          }
        }, this);

        this[index].Buttons = (
          <React.Fragment>
            <Button
              color="primary"
              href={"/newlyBuilt/details/" + this[index].Id}
              size="sm"
            >
              {Literals.Info}
            </Button>
            {!isUserRoleUser ? (
              <>
                {this[index].Status != InstanceStatusEnumType.StudyApproved ? (
                  <>
                    <Button
                      color="warning"
                      href={"/newlyBuilt/instance/" + this[index].Id}
                      size="sm"
                    >
                      {Literals.Actions}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Button
                  color="warning"
                  href={"/newlyBuilt/instance/" + this[index].Id}
                  size="sm"
                >
                  {Literals.Actions}
                </Button>
              </>
            )}

            <Button
              color="success"
              href={"/newlyBuilt/comments/" + this[index].Id}
              size="sm"
            >
              {Literals.Notes}
            </Button>
            <Button
              color="primary"
              href={"/newlyBuilt/files/" + this[index].Id}
              size="sm"
            >
              {Literals.Documents}
            </Button>
          </React.Fragment>
        );
      }, instances);

      //remove a collumn of mixanikos if not admin
      {
        let columnDefintions = ColumnDefinitions;
        if (!isUserRoleAdmin) {
          columnDefintions = ColumnDefinitions.filter(
            (x) => x.field != "Name"
          );
        }
        //remove id column
        var instanceCopies = [...instances];
        instanceCopies.forEach((object) => {
          delete object["Id"];
        });

        <>
          {this.setState({
            loading: false,
            datas: {
              columns: columnDefintions,
              rows: instanceCopies,
            },
          })}
        </>;
      }
      
      this.setState({ instances });
  }

  componentDidMount() {
    this.setUserDetails(
      this.apiDataResolver
    );     
  }

  async apiDataResolver() {
    //ovveride calls api to fetch data accordingly
    throw "Not Implemented Exception"
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{this.__PageTitle}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* <Button
                            color="success"
                            href="/supplier/newinstance"
                            size="sm"
                        >
                            Κατοχύρωση νέας αίτησης
                        </Button> */}
                    </Col>
                  </Row>

                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip="true"
                    data={this.state.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    noRecordsFoundLabel={LiteralsHelper.Grid.emptyData}
                    searchLabel={LiteralsHelper.Grid.search}                          
                    paginationLabel={[LiteralsHelper.Grid.previous, LiteralsHelper.Grid.next]}
                    infoLabel={LiteralsHelper.infoLabels}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                    responsive
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

        </Container>
      </>
    );
  }
}

export default NewlyBuiltInstancesGenericViewByState;
