import { WorkflowIdTypes } from "./enums/workflowIdTypes.enum";

export const WorkflowHelper = {
  WorkflowIdTypes: WorkflowIdTypes,
  isWorkflowIdTypeNewlyBuildStudy: isWorkflowIdTypeNewlyBuildStudy,
  isWorkflowIdTypeNewlyBuildAutopsy:  isWorkflowIdTypeNewlyBuildAutopsy,
  isWorkflowIdTypeNewlyBuildStudyFromInstance:  isWorkflowIdTypeNewlyBuildStudyFromInstance,
  isWorkflowIdTypeNewlyBuildAutopsyFromInstance:  isWorkflowIdTypeNewlyBuildAutopsyFromInstance,
};

function isWorkflowIdTypeNewlyBuildStudy(value) {
  return value == WorkflowIdTypes.NEWLY_BUILD_STUDY;
}

function isWorkflowIdTypeNewlyBuildAutopsy(value) {
  return value == WorkflowIdTypes.NEWLY_BUILD_AUTOPSY;
}

function isWorkflowIdTypeNewlyBuildStudyFromInstance(instance) {
  return isWorkflowIdTypeNewlyBuildStudy(instance.WorkflowId);
}

function isWorkflowIdTypeNewlyBuildAutopsyFromInstance(instance) {
  return isWorkflowIdTypeNewlyBuildAutopsy(instance.WorkflowId);
}
