

import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';

import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';
// import { ApiCallerHelper } from "../../../api/apiCallerHelper";
// import { ApiCallerHelperFiles } from "../../../api/apiCallerHelper.files";
// import { WorkflowHelper } from '../../../common/workflowHelper';
// import { UserRoleHelper } from '../../../common/userRoleHelper';
// import { ValidationHelper } from '../../../common/validationHelper';
// import { RedirectHelper } from '../../../common/redirectHelper';
// import { WorkflowInstancesStatusTypes } from '../../../common/enums/workflowInstancesStatusTypes.enum';

class TextComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        return (
            <>

                <Input
                    className="form-control-alternative"
                    defaultValue={this.props.defaultValue}
                    id={this.props.StepAndActionId}
                    placeholder={this.props.placeholder}
                    type={'text'}
                    disabled={this.props.disabled}
                    onChange={evt => this.props._onChange(evt)}
                />

            </>
        )
    }
}

export default TextComponent;