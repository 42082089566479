import NewlyBuiltInstancesGenericViewByState from "./newlyBuiltInstancesGenericViewByState";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";
class NewlyBuiltNewResearches extends NewlyBuiltInstancesGenericViewByState {
  constructor(props) {    
    super(props);      
  }  

  __PageTitle = "Νέες Μελέτες" 

  async apiDataResolver() {
    const result = await ApiCallerHelper.Workflowinstances.getNewResearchesAsync();    
    this.generateGenericWorkflowInstancesGridDataAndSetState(result);      
  }
}

export default NewlyBuiltNewResearches;
