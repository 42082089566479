import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class Assign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            isLoaded: false,
            user: [],
            externals: [],
            researchToAssign: -1,
            userToAssign: -1
        };
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    })

            fetch(process.env.REACT_APP_API_LINK + "/api/user/externals/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        //console.log(result);
                        this.setState({
                            externals: result,
                        });
                    },
                    (error) => {

                    })


        };
    }

    researchChange = (e) => {
        //console.log(e.target.value);
        this.state.researchToAssign = e.target.value;
    }

    userChange = (e) => {
        this.state.userToAssign = e.target.value;
    }

    submitChanges = (e) => {
        fetch(process.env.REACT_APP_API_LINK + '/api/user/assigntouser/', {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
                'research': this.state.researchToAssign,
                'user': this.state.userToAssign,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.error)
                    alert('Η Μελέτη δεν βρέθηκε στο σύστημα ή έχει ανατεθεί σε άλλον μηχανικό!');
                else
                    alert('Η ανάθεση έγινε επιτυχώς');
            })
    }

    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Ανάθεση σε Εξωτερικό Συνεργάτη</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={e => this.submitChanges(e)}
                                                size="sm"
                                            >
                                                Αποθήκευση αλλαγών
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Βασικα στοιχεια
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Αριθμός Μελέτης
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            onChange={e => { this.researchChange(e) }}
                                                            id="input-username"
                                                            placeholder="Αριθμός Μελέτης"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Ανάθεση Σε:
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            // defaultValue={this.state.user.Roles}
                                                            id="input-first-name"
                                                            placeholder="First name"
                                                            type="select"

                                                            onChange={e => { this.userChange(e) }}
                                                        >
                                                            <option value="-1" selected disabled hidden>Επιλέξτε Εξωτερικό Συνεργάτη</option>

                                                            {this.state.externals.map((Externals) =>
                                                                <option value={Externals.Id}>{Externals.DisplayName}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Assign;
