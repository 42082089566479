import {ApiCallerHelper} from "../../../api/apiCallerHelper"
import {UserRoleHelper} from "../../../common/userRoleHelper"
import React from "react";
import { RedirectHelper } from "../../../common/redirectHelper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';


import { BlockReserveLoading, CommonLoading } from 'react-loadingg';
import Moment from "moment";
import { MDBDataTable } from 'mdbreact';

class NewlyBuiltDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      details: [],
      consumer: [],
      meter: [],
      dict1: {},
      dict3: {},
      instanceSerialNumber: '',
      instanceStatus: '',
      instanceStartedAt: '',
      instanceSubmittedAt: '',
      instanceStepName: '',
      instanceId: '',
      instanceCompleted: false,
      loading: true,
      files: [],
      newautopsies: [],
      internalComment: ""
      // instanceId: ''
    };

    this.InternalCommentHelper.bind(this);

  }


  InternalCommentHelper = {
    bind : function(self) {
      self.InternalCommentHelper.getAndSetInternalComment = self.InternalCommentHelper.getAndSetInternalComment.bind(self);
      self.InternalCommentHelper.update = self.InternalCommentHelper.update.bind(self);  
      self.InternalCommentHelper.setInternalCommentState = self.InternalCommentHelper.setInternalCommentState.bind(self);  
      self.InternalCommentHelper.onChange = self.InternalCommentHelper.onChange.bind(self);  
      self.InternalCommentHelper.render = self.InternalCommentHelper.render.bind(self);  
    },
    setInternalCommentState: function(internalComment) {
      this.setState({        
        internalComment: internalComment,
      });  
    },
    onChange: function(event) {
      var comment = event.target.value;
      this.InternalCommentHelper.setInternalCommentState(comment);                
    },
    getAndSetInternalComment: function() {
      let instanceId = this.props.match.params.id; 
      ApiCallerHelper.Workflowinstances.getWorkflowinstancesInternalComment(instanceId, (res)=> {
        var lastEntryData = res.data.step1.slice(-1).pop();
        if (!lastEntryData) {return;}
        
        this.InternalCommentHelper.setInternalCommentState(lastEntryData.Comment);
      })     
    },
    update: function()  {
      let instanceId = this.props.match.params.id;          
      const comment = this.state.internalComment;
      this.setState(
        {
          loading: true,
        },
        () => {
          ApiCallerHelper.Workflowinstances.postWorkflowinstancesInternalComment(
            instanceId,
            comment,
            (res) => {
              this.InternalCommentHelper.setInternalCommentState(comment);
              RedirectHelper.RedirectTo.reloadPage();
            }
          );
        }
      );           
    },
    render: function (user) {
      var title = "Εσωτερικά Σχόλια";
      if (!this.InternalCommentHelper.getIsVisible(user)) {
        return <></>;
      }
      return (        
        <><br/>
          <Col md="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-internal-comment"
              >
                {title}
              </label>
              <Input
                  id="input-internal-comment"
                  name="input-internal-comment"
                  className="form-control-alternative"
                  placeholder={'Νέο σχόλιο'}
                  type={'textarea'}
                  onChange={this.InternalCommentHelper.onChange}     
                  value={this.state.internalComment}                               
              />
            </FormGroup>
          </Col>

          <Button
            color="success"
            href="#"
            onClick={() => {
              this.InternalCommentHelper.update()            
            }}
            size="sm"
          >
            Αποθήκευση
          </Button>
        </>
      );
    },
    getIsVisible: function (user) {
      return UserRoleHelper.isAdmin(user);
    },
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          this.setState({
            userDetails: result
          },()=>{
            this.InternalCommentHelper.getAndSetInternalComment();
          });
        })

      const handle = this.props.match.params.id;

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          this.state.instanceStatus = result.Status;
          this.state.instanceCompleted = result.Completed;
          console.log(result);
          switch (this.state.instanceStatus) {
            case 1:
              this.state.instanceStepName = 'Υποβολή Μελέτης';
              break;

            case 3:
              this.state.instanceStepName = 'Θεωρημένη Μελέτη';
              break;

            default:
              this.state.instanceStepName = 'Κατάσταση';
          }
          this.state.instanceId = result.Id;
          this.state.instanceSerialNumber = result.SerialNumber;
          this.state.instanceStartedAt = result.StartedAt;
          this.state.instanceSubmittedAt = result.SubmittedAt;
        })

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancestep/" + handle + '/step/1/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          let steps = [...result.actionsInfused];
          //console.log(steps);
          var self = this;
          steps.forEach(function (element, index) {
            self.state.dict1[element.action.Name] = element.data.Data;

          }, steps);
          delete this.state.dict1['Σχετικά Έγγραφα'];
          delete this.state.dict1['Θεωρημένη Μελέτη'];
          this.setState({
            dict1: this.state.dict1,
          });


          this.setState({
            loading: false
          });


          //console.log(this.state.dict1);
        })

      if (this.state.instanceStatus == 3) {

        //     await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancestep/" + handle + '/step/3/', {
        //       method: 'get',
        //       headers: new Headers({
        //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //       })
        //     })
        //       .then(res => res.json())
        //       .then((result) => {
        //         //console.log(result);
        //         let steps = [...result.actionsInfused];
        //         //console.log(steps);
        //         var self = this;
        //         steps.forEach(function (element, index) {
        //           self.state.dict3[element.action.Description] = element.data.Data;

        //         }, steps);
        //         delete this.state.dict3['Υπόδειγμα Βεβαίωσης Αυτοψίας'];
        //         delete this.state.dict3['Εκτός Σχεδίου Απάντηση'];
        //         this.setState({
        //           dict3: this.state.dict3,
        //           loading: false
        //         });
        //         //console.log(this.state.dict3);
        //       })

        await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/newautopsies/" + handle + '/', {
          method: 'get',
          headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        })
          .then(res => res.json())
          .then((result) => {
            //console.log(result);
            var self = this;
            result.forEach(function (part, index) {
              this[index].SubmittedAt = this[index].SubmittedAt.split('T')[0];
              this[index].Status = this[index].Declined == true ? 'Αυτοψία Υπό Επανεξέταση' : this[index].Status == 1 ? 'Υποβολή Αυτοψίας' : this[index].Status == 3 ? 'Βεβαιωμένη Αυτοψία' : '';

              this[index].Buttons =
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/newlyBuilt/autopsy/" + this[index].Id}
                    size="sm"
                  >
                    Στοιχεία
                  </Button>

                  {this[index].Status != 'Βεβαιωμένη Αυτοψία' ?
                    <>
                      <Button
                        color="warning"
                        href={"/newlyBuilt/instance/" + this[index].Id}
                        size="sm"
                      >
                        Ενέργειες
                      </Button>
                    </>
                    : <></>
                  }


                  <Button
                    color="success"
                    href={"/newlyBuilt/comments/" + this[index].Id}
                    size="sm"
                  >
                    Σχόλια
                  </Button>
                  <Button
                    color="primary"
                    href={"/newlyBuilt/files/" + this[index].Id}
                    size="sm"
                  >
                    Έγγραφα
                  </Button>
                </React.Fragment>
            }, result);

            this.setState({
              datas: {
                columns: [
                  {
                    label: 'Αριθμος Αυτοψιας ▼',
                    field: 'SerialNumber',
                    sort: 'asc',
                    width: 100
                  },
                  {
                    label: 'Κατασταση ▼',
                    field: 'Status',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Ημ. Τελ. Ανανεωσης ▼',
                    field: 'SubmittedAt',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Ανατεθηκε Σε ▼',
                    field: 'EditedBy',
                    sort: 'asc',
                    width: 150
                  },
                  // {
                  //   label: 'Εργοδοτης ▼',
                  //   field: 'ergodotis',
                  //   sort: 'asc',
                  //   width: 150
                  // },
                  // {
                  //   label: 'Μηχανικος ▼',
                  //   field: 'mixanikos',
                  //   sort: 'asc',
                  //   width: 150
                  // },
                  // {
                  //   label: 'Πολη ▼',
                  //   field: 'poli',
                  //   sort: 'asc',
                  //   width: 150
                  // },
                  {
                    label: 'Μεταβαση στην Αυτοψια▼',
                    field: 'Buttons',
                    sort: 'asc',
                    width: 150
                  }
                ],
                rows: result
              }
            });

          })
      }
      const result2 = await axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + handle + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          this.setState({
            loading: false,
            files: res.data
          });
          //console.log(res);
          //console.log(this.state.files);
        })
    };
  }

  uploadFile(evt, actionid) {
    const formData = new FormData();
    var res = actionid.split("/");

    if (evt.target.files.length > 1) {
      var self = this;

      for (let i = 0; i < evt.target.files.length; i++) {
        let file = evt.target.files.item(i);

        const newformData = new FormData();
        newformData.append(
          "files",
          file
        );

        fetch(
          'https://filemanager.deda.gr/',
          {
            method: 'POST',
            body: newformData,
          }
        )
          .then((response) => response.json())
          .then((result) => {
            // //console.log('Success:', result);

            fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
              method: 'POST',
              headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
              }),
              body: JSON.stringify({
                'Name': result[0].name,
                'Path': result[0].path,
                'Size': result[0].size,
                'Type': result[0].type,
                'InstanceId': self.state.instanceId,
                'StepId': res[0],
                'ActionId': res[1]
              })
            })
              .then(data => {
                // //console.log(data);

                axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + self.state.instanceId + "/", {
                  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                  .then(res => {
                    self.setState({
                      loading: false,
                      files: res.data
                    });
                  })
              })
          })
          .catch((error) => {
            console.error('Error:', error);
          });


      }



    }
    else {
      // Update the formData object 
      formData.append(
        "files",
        evt.target.files[0]
      );

      // Details of the uploaded file 
      // //console.log(evt.target.files[0]);
      // //console.log(formData);


      fetch(
        'https://filemanager.deda.gr/',
        {
          method: 'POST',
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // //console.log('Success:', result);

          fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
            method: 'POST',
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
              'Name': result[0].name,
              'Path': result[0].path,
              'Size': result[0].size,
              'Type': result[0].type,
              'InstanceId': this.state.instanceId,
              'StepId': res[0],
              'ActionId': res[1]
            })
          })
            .then(data => {
              // //console.log(data);

              axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instanceId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
              })
                .then(res => {
                  this.setState({
                    loading: false,
                    files: res.data
                  });
                })
            })
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

  }

  deleteFile = fileId => {
    if (localStorage.getItem('token')) {
      axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instanceId + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
          })
            .then(res => {
              this.setState({
                loading: false,
                files: res.data
              });
              //console.log(res);
            })
        })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <GenericHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Μελέτης</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* <Button
                            href="/supplier/instancesettings"
                            color='primary'
                            size="sm"
                          >
                            Ρυθμίσεις
                          </Button> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>                   
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-serialno"
                              >
                                Α/Α Μελέτης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instanceSerialNumber}
                                disabled
                                id="input-serialno"
                                placeholder="Α/Α"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3" >
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-status"
                              >
                                Κατάσταση
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instanceStepName}
                                // defaultValue={Moment(this.state.details.activation).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-status"
                                placeholder="Κατάσταση"
                                type="text"
                                disabled={true}
                              // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-startedat"
                              >
                                Ημερομηνία Υποβολής
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-startedat"
                                defaultValue={this.state.instanceStartedAt.split('T')[0]}
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-submittedat"
                              >
                                Ημερομηνία Τελευταίας Τροποποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instanceSubmittedAt.split('T')[0]}
                                id="input-submittedat"
                                placeholder="Ημερομηνία Τελευταίας Τροποποίησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>

                        </Row>
                      </div>                           
                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        {/* <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Μελετης
                        </h6> */}
                        {this.state.instanceCompleted ?
                          <>
                            {/* <Button color="primary" size="sm" href={"/newlyBuilt/formvisa/" + this.props.match.params.id}>Φόρμα Θεώρησης</Button> */}
                            <Button
                              color="success"
                              href={"/newlyBuilt/files/" + this.state.instanceId}
                              size="sm"
                            >
                              Έγγραφα
                            </Button> <br /><br /> <br /><br />
                          </>
                          :
                          <>
                          </>
                        }
                        <div className="pl-lg-4">
                          <Row>

                            {Object.entries(this.state.dict1)
                              .map(([key, value]) =>
                                <>
                                  <Col lg="3">
                                    <FormGroup>
                                      {key != 'Αριθμός Προηγούμενης Μελέτης' ?
                                        <>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                          >
                                            {key}
                                          </label>

                                          <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            defaultValue={value}
                                            placeholder={key}
                                            type="text"
                                            disabled
                                          />

                                        </>
                                        :
                                        <>
                                          {value != '' ?
                                            <>
                                              <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                              >
                                                {key}
                                              </label>
                                              <Input
                                                className="form-control-alternative"
                                                id="input-email"
                                                defaultValue={value}
                                                placeholder={key}
                                                type="text"
                                                disabled
                                              />
                                            </>
                                            :
                                            <>
                                            </>
                                          }
                                        </>
                                      }
                                      {/* <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                      >
                                        {key}
                                      </label>
                                      <Input
                                        className="form-control-alternative"
                                        id="input-email"
                                        defaultValue={value}
                                        placeholder={key}
                                        type="text"
                                        disabled
                                      /> */}
                                    </FormGroup>
                                  </Col>
                                </>)}
                          </Row>

                        </div>
                      </div>

                      <hr className="my-4" />
                      {this.state.instanceStatus == 3 ?
                        <>
                          <div className="pl-lg-4">

                            <Row>
                              <Col lg="10">
                                <b>Θεωρημένη Μελέτη</b> <br /><br />
                                {this.state.userDetails.PersonRole == 'Admin' ?
                                  <>
                                    <label className="btn btn-primary" htmlFor={'858b4550-1136-4a42-80eb-e5722f8d18c7' + "/" + '20abd95c-321a-4220-8b44-a0c34be994d0'} >
                                      Επιλογή αρχείου
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      // defaultValue={ dynamicActions.data.Data }
                                      id={'858b4550-1136-4a42-80eb-e5722f8d18c7' + "/" + '20abd95c-321a-4220-8b44-a0c34be994d0'}
                                      // placeholder={dynamicActions.action.Description}
                                      style={{ visibility: "hidden" }}
                                      type={'file'}
                                      // disabled={!dynamicActions.action.Required}
                                      onChange={evt => this.uploadFile(evt, '858b4550-1136-4a42-80eb-e5722f8d18c7' + "/" + '20abd95c-321a-4220-8b44-a0c34be994d0')}
                                      accept="application/pdf"
                                      multiple
                                    />

                                  </>
                                  :
                                  <>
                                  </>}
                              </Col>
                            </Row>
                            <Table className="align-items-center table-flush" style={{ marginBottom: '20px' }} responsive>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Ονομα Αρχειου</th>
                                  <th scope="col">Ημερομηνια Αναρτησης</th>
                                  <th scope="col">Ενεργειες</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.files.map((dynamicFiles) =>
                                  <>
                                    {'20abd95c-321a-4220-8b44-a0c34be994d0' == dynamicFiles.ActionId ?
                                      <>
                                        <tr>
                                          <td>{dynamicFiles.FileName}</td>
                                          <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                          <td>
                                            <Button
                                              color="primary"
                                              href={dynamicFiles.Link}
                                              target="_blank"
                                              size="sm"
                                              title="Προβολή"
                                            >
                                              <i className="fas fa-eye" />
                                            </Button>
                                            {this.state.userDetails.PersonRole == 'Admin' ?
                                              <>
                                                <Button
                                                  color="danger"
                                                  onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                  size="sm"
                                                  title="Διαγραφή"
                                                >
                                                  <i className="fas fa-trash" />
                                                </Button>
                                              </> : <></>
                                            }

                                          </td>
                                        </tr>
                                      </> : <></>
                                    }
                                  </>
                                )}
                              </tbody>
                            </Table>
                            <h6 className="heading-small text-muted mb-4">
                              ΣΥΣΧΕΤΙΖΟΜΕΝΕΣ ΑΥΤΟΨΙΕΣ
                            </h6>
                            <CardHeader>
                              <MDBDataTable
                                bordered
                                exportToCSV
                                hover
                                strip
                                responsive
                                data={this.state.datas}
                                id="mdbdttable"
                                entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                searchLabel="Αναζήτηση"
                                noDataComponent="Δεν υπάρχουν δεδομένα"
                                paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                infoLabel={['Εμφανίζονται', 'έως', 'από', 'αυτοψίες']}
                                entriesLabel={'Εμφάνιση Αυτοψιών ανά: '}
                              />
                            </CardHeader>
                          </div>
                        </> : <></>
                      }
                      {this.InternalCommentHelper.render(this.state.userDetails)}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

  }
}

export default NewlyBuiltDetails;
