import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import 'moment/locale/el';

class YpodeigmaVevaiosis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            data: [],
            serialnumbers: [],
            userDetails: [],
            userEmail: '',
            userPhone: '',
            dict: {},
            serialNumber: '',
            researchId: ''
        };

    }

    async componentDidMount() {
        document.getElementById('sidenav-main').style.display = 'none';
        document.getElementById('navbar-main').style.display = 'none';
        document.querySelector('.main-content').style.margin = '0px';
        document.querySelector('body').style.backgroundColor = '#fff';
        document.querySelector('.footer').style.backgroundColor = '#fff';
        document.querySelector('.copyright').style.display = 'none';

        if (localStorage.getItem('token')) {

            let instance = window.location.href.split('/')[5];
            // let virtual_step = window.location.href.split('/')[6];

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);
                    this.setState({
                        userDetails: result,
                        userEmail: result.Email,
                        userPhone: ''
                    });
                })
            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/researchid/" + instance + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);
                    this.state.researchId = result;
                })

            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancedata/" + instance + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    let steps = [...result.stepsInfused];
                    this.state.serialNumber = result.instance.SerialNumber;
                    //console.log(steps);
                    var self = this;
                    steps.forEach(function (element, index) {
                        element.actions.forEach(function (part) {
                            self.state.dict[part.action.Description] = part.data.Data;
                        });

                    }, steps);
                    this.setState({
                        dict: this.state.dict,
                        serialNumber: this.state.serialNumber
                    });
                    //console.log(this.state.dict);
                    // this.setState({
                    //     userDetails: result,
                    //     userEmail: result.Email,
                    //     userPhone: ''
                    // });
                })
            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancedata/" + this.state.researchId + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    let steps = [...result.stepsInfused];
                    this.state.serialNumber = result.instance.SerialNumber;
                    //console.log(steps);
                    var self = this;
                    steps.forEach(function (element, index) {
                        element.actions.forEach(function (part) {
                            self.state.dict[part.action.Description] = part.data.Data;
                        });

                    }, steps);
                    this.setState({
                        dict: this.state.dict,
                        serialNumber: this.state.serialNumber
                    });
                    console.log(this.state.dict);
                    // this.setState({
                    //     userDetails: result,
                    //     userEmail: result.Email,
                    //     userPhone: ''
                    // });
                })

        }
        else {
            this.props.history.goBack();
        }


    }

    print() {
        // printButton
        document.getElementById('printSection').style.display = 'none';
        window.print();
    }

    getCurrentDate() {
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        return date;
    }

    render() {
        window.onafterprint = function () {
            // //console.log("Printing completed...");
            document.getElementById('printSection').style.display = 'block';
        }
        return (
            <>

                <div class="container-fluid" style={{ margin: '0' }}>




                    {/* <GenericHeader /> */}
                    <div id="printSection" style={{ marginTop: '20px', zIndex: '1000', marginBottom: '20px' }}>

                        <Button
                            color="primary"
                            size="sm"
                            title="Στοιχεία"
                            onClick={() => this.print()}
                        >
                            Εκτύπωση
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            title="Επιστροφή στην πλατφόρμα"
                            onClick={() => window.location.href = "/newlyBuilt/index"}
                        >
                            Επιστροφή στην πλατφόρμα
                        </Button>
                    </div>

                </div>

                <React.Fragment>
                    <div className="container-fluid">
                        <div className="row" style={{ padding: '50px' }}>
                            <div className="col-sm">
                                <img width="154" height="136" src="https://deda.gr/wp-content/uploads/2020/04/Picture1-150x150.jpg" align="left" hspace="12" alt="Logo, company name Description automatically generated" />

                            </div>
                            <div className="col-sm" style={{ marginTop: '40px' }}>
                                <h5 style={{ textAlign: 'right' }}>Αθήνα, {this.getCurrentDate()}</h5>
                                <h4 style={{ textAlign: 'right' }}>ΑΥΞ. ΑΡΙΘΜ. ΜΕΛΕΤΗΣ ΔΕΔΑ: {this.state.serialNumber} </h4>
                                {/* <h4 style={{ textAlign: 'right' }}>ΑΡ. ΕΞΕΡΧΟΜΕΝΟΥ:</h4> */}

                            </div>


                        </div>
                        <h2 style={{ textAlign: 'center', fontWeight: '700' }}>ΒΕΒΑΙΩΣΗ ΔΙΕΞΑΓΩΓΗΣ ΑΥΤΟΨΙΑΣ</h2>
                        <p style={{ color: 'black', fontWeight: '400', marginTop: '10px' }}>
                            Ο υπογράφων βεβαιώνει ότι την {this.getCurrentDate()} διεξήχθη η προβλεπόμενη από το άρθρο 30 παρ.7 του Ν.3175/2003 αυτοψία της εσωτερικής εγκατάστασης φυσικού αερίου της οικοδομής με τα στοιχεία:
                        </p>
                        <div className="container">
                            <table className="table" style={{ width: '50%', marginTop: '30px' }}>
                                {/* <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First</th>
                                    <th scope="col">Last</th>
                                    <th scope="col">Handle</th>
                                </tr>
                            </thead> */}
                                <tbody>
                                    {/* <tr>
                                        <td>Ιδιοκτήτης</td>
                                        <td>{this.state.dict["Ιδιοκτήτης"]}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Αρ. Οικοδομικής Άδειας</td>
                                        <td>{this.state.dict["Αρ. Οικοδομικής Άδειας"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Τίτλος Έργου</td>
                                        <td>{this.state.dict["Τίτλος Έργου"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Διεύθυνση</td>
                                        <td>{this.state.dict["Διεύθυνση"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Νομός/Δήμος</td>
                                        <td>{this.state.dict["Νομός/Δήμος"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Οικ. Τετράγωνο</td>
                                        <td>{this.state.dict["Οικοδομικό Τετράγωνο"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Μελετητής Εσωτερικής Εγκατάστασης αερίου</td>
                                        <td>{this.state.dict["Μελετητής Εσωτερικής Εγκατάστασης Αερίου"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Επιβλέπων Μηχανικός Εργασιών Εσωτερικής Εγκατάστασης αερίου</td>
                                        <td>{this.state.dict["Επιβλέπων Μηχανικός Εργασιών Εσωτερικής Εγκατάστασης Αερίου"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Αδειοδοτημένος Εγκαταστάτης	</td>
                                        <td>{this.state.dict["Αδειοδοτημένος Εγκαταστάτης"]}	</td>

                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <p style={{ color: 'black', fontWeight: '400', marginTop: '20px' }}>
                            Από την αυτοψία προέκυψε ότι η εγκατάσταση υλοποιήθηκε σε συμφωνία με την μελέτη καυσίμου αερίου που θεωρήθηκε με Αύξων Αριθμό Μελέτης {this.state.serialNumber}.
                            Επισυνάπτονται οι Υπεύθυνες Δηλώσεις που εκδόθηκαν σύμφωνα με το άρθρο 30 παράγραφος 7 του Ν.3175/2003 του Επιβλέποντος και του Αδειοδοτημένου Εγκαταστάτη. <br /><br />

                            Η παρούσα βεβαίωση εκδίδεται αποκλειστικά για την χρήση που προβλέπεται στο άρθρο 30 παράγραφος 7 του Ν.3175/2003.

                        </p>

                        <div className="col-sm">
                            {/* <img width="154" height="136" src="https://deda.gr/wp-content/uploads/2020/04/Picture1-150x150.jpg" align="left" hspace="12" alt="Logo, company name Description automatically generated" /> */}

                        </div>
                        <div className="col-sm" style={{ marginTop: '40px' }}>
                            <h3 style={{ textAlign: 'right', color: 'darkblue' }}>Για τη ΔΕΔΑ</h3>
                            <h4 style={{ textAlign: 'right' }}>Ο εξουσιοδοτημένος μηχανικός</h4>
                            {/* <h4 style={{ textAlign: 'right' }}>Φωκιανός Ιωάννης</h4> */}

                        </div>
                        <br />
                        <br />
                        ΠΥΡΓΟΣ ΑΘΗΝΩΝ, Λ. ΜΕΣΟΓΕΙΩΝ 2-4, 11527 ΑΘΗΝΑ, ΤΗΛΕΦΩΝΟ: 216 2000 401-5, FAX: 210 2750 249
                        ΑΡΙΘΜΟΣ Γ.Ε.ΜΗ.: 141016101000


                    </div>
                </React.Fragment>
            </>
        )
    };

}

export default YpodeigmaVevaiosis;