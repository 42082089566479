export const RedirectHelper = {
    RedirectTo: {
      reloadPage : () => { window.location.reload();},
      reloadPageFromCache : () => { window.location.reload(false);},
      home: () => _location("/basicLayout/index/"),
      home2: () => _location("/dashboardBasic/index/"),
      login: () => _location("/auth/login"),
      adminDetails: (workflowInstanceId) => _location("/admin/details/" + workflowInstanceId),
      details: (workflowInstanceId) => _location("/basicLayout/instance/" + workflowInstanceId),     
      autopsy: (workflowInstanceId) => _location("/newlyBuilt/autopsy/" + workflowInstanceId)
    },
  };
  
  function _location(route) {
    window.location.replace(route);
  }
  
  