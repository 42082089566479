import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

import { CommonLoading } from 'react-loadingg';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import "../../../assets/css/datatables.css";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

class NewlyBuiltIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: { PersonRole: 'Admin' },
      instances: [],
      dict: {},
      startDate: new Date(),
      otherDay: new Date(),
      startDateAutopsy: new Date(),
      otherDayAutopsy: new Date(),
      autopsies: [],
      initRole: {},
      loading: true
    };

    this.state.startDate.setMonth(this.state.otherDay.getMonth() - 2);
    this.state.otherDay.setDate(this.state.otherDay.getDate() + 1);
    this.state.startDateAutopsy.setMonth(this.state.otherDayAutopsy.getMonth() - 2);
    this.state.otherDayAutopsy.setDate(this.state.otherDayAutopsy.getDate() + 1);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChangeAutopsy = this.handleChangeAutopsy.bind(this);
    this.handleChangeAutopsy2 = this.handleChangeAutopsy2.bind(this);
  };

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          //console.log(result);
          this.setState({
            userDetails: result,
            initRole: result
          });
        })

      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/depallfilter/", {
        method: 'post',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: JSON.stringify({
          dateFrom: this.state.startDate,
          dateTo: this.state.otherDay,
        })
      })
        .then(res => res.json())
        .then((result) => {
          console.log(result);
          // //console.log(result[1]['0ffa69c6-8223-4532-9fca-74a615ae31ca']);
          const instances2 = result[0];
          var self = this;
          instances2.forEach(function (part, index) {
            this[index].SubmittedAt = this[index].SubmittedAt.split('T')[0];
            this[index].Status = this[index].Declined == true ? 'Μελέτη Υπό Επανεξέταση' : this[index].Status == 1 ? 'Υποβολή Μελέτης' : this[index].Status == 3 ? 'Θεωρημένη Μελέτη' : '';
            var mythis = this;

            result[1][this[index].Id].forEach(function (element, counter) {
              if (element[1] == 'dc5c2341-0fe4-4912-b09c-2497855f7776') {
                mythis[index].ergodotis = element[2];
              }
              if (element[1] == 'fcf1056c-4d54-46c8-9494-0c0c0d5dc6bc') {
                mythis[index].mixanikos = element[2];
              }
              if (element[1] == '6cc71d92-7162-4f30-b38c-8a953381b571') {
                mythis[index].poli = element[2];
              }
              if (element[1] == '4cc7efc4-51f4-4b72-b74d-9cf6e23f909b') {
                mythis[index].anatheorisi = element[2];
                if (element[2] == '') {
                  mythis[index].anatheorisi = '-';
                }
              }

            }, result[1][this[index].Id]);

            //console.log(result[1][this[index].Id][1][2]);
            // this[index].ergodotis = result[1][this[index].Id][0];
            this[index].Buttons =
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/newlyBuilt/details/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία
                </Button>
                {self.state.userDetails.PersonRole != 'User' ?
                  <>
                    {this[index].Status != 'Θεωρημένη Μελέτη' ?
                      <>
                        <Button
                          color="warning"
                          href={"/newlyBuilt/instance/" + this[index].Id}
                          size="sm"
                        >
                          Ενέργειες
                        </Button>
                      </>
                      : <></>
                    }
                  </>
                  :
                  <>
                    <Button
                      color="warning"
                      href={"/newlyBuilt/instance/" + this[index].Id}
                      size="sm"
                    >
                      Ενέργειες
                    </Button>
                  </>
                }

                <Button
                  color="success"
                  href={"/newlyBuilt/comments/" + this[index].Id}
                  size="sm"
                >
                  Σχόλια
                </Button>
                <Button
                  color="primary"
                  href={"/newlyBuilt/files/" + this[index].Id}
                  size="sm"
                >
                  Έγγραφα
                </Button>
              </React.Fragment>
            // //console.log(index)
          }, instances2);

          var instanceCopies = [...instances2];
          instanceCopies.forEach(object => {
            delete object['Id'];
          });

          //console.log(instanceCopies);
          {
            self.state.initRole.PersonRole == 'Admin' ?
              <>
                {
                  this.setState({
                    loading:false,
                    datas: {
                      columns: [
                        {
                          label: 'Α/Α  ▼',
                          field: 'SerialNumber',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Αριθμος μελετης αναθεωρησης  ▼',
                          field: 'anatheorisi',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Κατασταση ▼',
                          field: 'Status',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ημ. Τελ. Ανανεωσης ▼',
                          field: 'SubmittedAt',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ανατεθηκε Σε ▼',
                          field: 'EditedBy',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Εργοδοτης ▼',
                          field: 'ergodotis',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Μηχανικος ▼',
                          field: 'Name',
                          sort: 'asc',
                          width: 150
                        },

                        {
                          label: 'Τοποθεσια ▼',
                          field: 'poli',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Παλια Μελετη ▼',
                        //   field: 'anatheorisi',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Μεταβαση στη Μελετη▼',
                          field: 'Buttons',
                          sort: 'asc',
                          width: 150
                        }
                      ],
                      rows: instanceCopies
                    }
                  })
                }
              </>
              :
              <>
                {
                  this.setState({
                    loading:false,
                    datas: {
                      columns: [
                        {
                          label: 'Α/Α  ▼',
                          field: 'SerialNumber',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Αριθμος μελετης αναθεωρησης  ▼',
                          field: 'anatheorisi',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Κατασταση ▼',
                          field: 'Status',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ημ. Τελ. Ανανεωσης ▼',
                          field: 'SubmittedAt',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ανατεθηκε Σε ▼',
                          field: 'EditedBy',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Εργοδοτης ▼',
                          field: 'ergodotis',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Μηχανικος ▼',
                        //   field: 'Name',
                        //   sort: 'asc',
                        //   width: 150
                        // },

                        {
                          label: 'Τοποθεσια ▼',
                          field: 'poli',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Παλια Μελετη ▼',
                        //   field: 'anatheorisi',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Μεταβαση στη Μελετη▼',
                          field: 'Buttons',
                          sort: 'asc',
                          width: 150
                        }
                      ],
                      rows: instanceCopies
                    }
                  })
                }
              </>
          }

          //console.log(result[1])
          const instances = result[0];
          this.setState({ instances });
          //console.log(instances);
        })

      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/autopsiesfilter/", {
        method: 'post',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: JSON.stringify({
          dateFrom: this.state.startDateAutopsy,
          dateTo: this.state.otherDayAutopsy,
        })
      })
        .then(res => res.json())
        .then((result) => {
          console.log(result);
          // //console.log(result[1]['0ffa69c6-8223-4532-9fca-74a615ae31ca']);
          const instances2 = result[0];
          var self = this;
          instances2.forEach(function (part, index) {
            this[index].SubmittedAt = this[index].SubmittedAt.split('T')[0];
            this[index].Status = this[index].Declined == true ? 'Αυτοψία Υπό Επανεξέταση' : this[index].Status == 1 ? 'Αυτοψία Μελέτης' : this[index].Status == 3 ? 'Βεβαιωμένη Αυτοψία' : '';
            
            var mythis = this;
            result[1][this[index].Id].forEach(function (element, counter) {
              if (element[1] == 'd87ac0b5-bcbb-420b-913f-1a95799e9530') {
                mythis[index].meleti = element[2];
              }
              if (element[1] == 'fcf1056c-4d54-46c8-9494-0c0c0d5dc6bc') {
                mythis[index].mixanikos = element[2];
              }
              if (element[1] == '6cc71d92-7162-4f30-b38c-8a953381b571') {
                mythis[index].poli = element[2];
              }
              if (element[1] == '4cc7efc4-51f4-4b72-b74d-9cf6e23f909b') {
                mythis[index].anatheorisi = element[2];
                if (element[2] == '') {
                  mythis[index].anatheorisi = '-';
                }
              }

            }, result[1][this[index].Id]);

            //console.log(result[1][this[index].Id][1][2]);
            // this[index].ergodotis = result[1][this[index].Id][0];
            this[index].Buttons =
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/newlyBuilt/autopsy/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία
                </Button>
                {self.state.userDetails.PersonRole != 'User' ?
                  <>
                    {this[index].Status != 'Βεβαιωμένη Αυτοψία' ?
                      <>
                        <Button
                          color="warning"
                          href={"/newlyBuilt/instance/" + this[index].Id}
                          size="sm"
                        >
                          Ενέργειες
                        </Button>
                      </>
                      : <></>
                    }
                  </>
                  :
                  <>
                    <Button
                      color="warning"
                      href={"/newlyBuilt/instance/" + this[index].Id}
                      size="sm"
                    >
                      Ενέργειες
                    </Button>
                  </>
                }

                <Button
                  color="success"
                  href={"/newlyBuilt/comments/" + this[index].Id}
                  size="sm"
                >
                  Σχόλια
                </Button>
                <Button
                  color="primary"
                  href={"/newlyBuilt/files/" + this[index].Id}
                  size="sm"
                >
                  Έγγραφα
                </Button>
              </React.Fragment>
            // //console.log(index)
          }, instances2);

          var instanceCopies = [...instances2];
          instanceCopies.forEach(object => {
            delete object['Id'];
          });

          //console.log(instanceCopies)
          {
            self.state.initRole.PersonRole == 'Admin' ?
              <>
                {
                  this.setState({
                    autopsies: {
                      columns: [
                        {
                          label: 'Α/Α  ▼',
                          field: 'SerialNumber',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'συνδεδεμενη μελετη  ▼',
                          field: 'meleti',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Κατασταση ▼',
                          field: 'Status',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ημ. Τελ. Ανανεωσης ▼',
                          field: 'SubmittedAt',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ανατεθηκε Σε ▼',
                          field: 'EditedBy',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Εργοδοτης ▼',
                        //   field: 'ergodotis',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Μηχανικος ▼',
                          field: 'Name',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Τοποθεσια ▼',
                        //   field: 'poli',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Επιβλεπων Μηχανικος ▼',
                          field: 'SupervisingEngineerName',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Μεταβαση στην Αυτοψια ▼',
                          field: 'Buttons',
                          sort: 'asc',
                          width: 150
                        }
                      ],
                      rows: instanceCopies
                    }
                  })
                }
              </>
              :
              <>
                {
                  this.setState({
                    autopsies: {
                      columns: [
                        {
                          label: 'Α/Α  ▼',
                          field: 'SerialNumber',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'συνδεδεμενη μελετη  ▼',
                          field: 'meleti',
                          sort: 'asc',
                          width: 100
                        },
                        {
                          label: 'Κατασταση ▼',
                          field: 'Status',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ημ. Τελ. Ανανεωσης ▼',
                          field: 'SubmittedAt',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Ανατεθηκε Σε ▼',
                          field: 'EditedBy',
                          sort: 'asc',
                          width: 150
                        },
                        // {
                        //   label: 'Εργοδοτης ▼',
                        //   field: 'ergodotis',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Μηχανικος ▼',
                        //   field: 'mixanikos',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Τοποθεσια ▼',
                        //   field: 'poli',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        // {
                        //   label: 'Παλια Μελετη ▼',
                        //   field: 'anatheorisi',
                        //   sort: 'asc',
                        //   width: 150
                        // },
                        {
                          label: 'Επιβλεπων Μηχανικος ▼',
                          field: 'SupervisingEngineerName',
                          sort: 'asc',
                          width: 150
                        },
                        {
                          label: 'Μεταβαση στην Αυτοψια ▼',
                          field: 'Buttons',
                          sort: 'asc',
                          width: 150
                        }
                      ],
                      rows: instanceCopies
                    }
                  })
                }
              </>
          }


          const instances = result[0];

          this.setState({ instances });
          //console.log(instances);
        })
    };
  }

  getDates(startDate, endDate) {
    var dates = [],
      currentDate = startDate,
      addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {

      dates.push(currentDate.toLocaleDateString('en-GB'));
      currentDate = addDays.call(currentDate, 1);
    }
    this.state.array = dates;
    return dates;
  };

  getDates_call(date, otherDate) {
    var dates = this.getDates(date, otherDate);
  }

  handleChange(date) {
    //console.log(date);
    this.setState({
      startDate: date
    });

    this.getDates_call(date, this.state.otherDay);

    this.fetchDEDAInstances(date, this.state.otherDay);
  }

  handleChange2(date) {
    this.setState({
      otherDay: date
    })

    this.getDates_call(this.state.startDate, date);

    this.fetchDEDAInstances(this.state.startDate, date);

  }

  handleChangeAutopsy(date) {
    //console.log(date);
    this.setState({
      startDateAutopsy: date
    });

    this.getDates_call(date, this.state.otherDayAutopsy);

    this.fetchDEDAAutopsies(date, this.state.otherDayAutopsy);
  }

  handleChangeAutopsy2(date) {
    this.setState({
      otherDayAutopsy: date
    })

    this.getDates_call(this.state.startDateAutopsy, date);

    this.fetchDEDAAutopsies(this.state.startDateAutopsy, date);

  }

  fetchDEDAInstances = (from = '', to = '') => {
   
    var fromArray = from.toLocaleDateString('en-GB').split('/');
    var toArray = to.toLocaleDateString('en-GB').split('/');

    var correctFromDate = fromArray[2] + '-' + fromArray[1] + '-' + fromArray[0];
    var correctToDate = toArray[2] + '-' + toArray[1] + '-' + toArray[0];
   
    //console.log(correctFromDate, correctToDate);

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/depallfilter/", {
        method: 'post',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: JSON.stringify({
          dateFrom: correctFromDate,
          dateTo: correctToDate,
        })
      })
        .then(res => res.json())
        .then(result => {
          //console.log(result);
          var self = this;
          try {
            const instances2 = result[0];
            var self = this;
            instances2.forEach(function (part, index) {
              this[index].SubmittedAt = this[index].SubmittedAt.split('T')[0];
              this[index].Status = this[index].Declined == true ? 'Μελέτη Υπό Επανεξέταση' : this[index].Status == 1 ? 'Υποβολή Μελέτης' : this[index].Status == 3 ? 'Θεωρημένη Μελέτη' : '';
              var mythis = this;

              result[1][this[index].Id].forEach(function (element, counter) {
                if (element[1] == 'dc5c2341-0fe4-4912-b09c-2497855f7776') {
                  mythis[index].ergodotis = element[2];
                }
                if (element[1] == 'fcf1056c-4d54-46c8-9494-0c0c0d5dc6bc') {
                  mythis[index].mixanikos = element[2];
                }
                if (element[1] == '6cc71d92-7162-4f30-b38c-8a953381b571') {
                  mythis[index].poli = element[2];
                }
              }, result[1][this[index].Id]);

              //console.log(result[1][this[index].Id][1][2]);

              // this[index].ergodotis = result[1][this[index].Id][0];
              this[index].Buttons =
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/newlyBuilt/details/" + this[index].Id}
                    size="sm"
                  >
                    Στοιχεία
                  </Button>
                  {self.state.userDetails.PersonRole != 'User' ?
                    <>
                      {this[index].Status != 'Θεωρημένη Μελέτη' ?
                        <>
                          <Button
                            color="warning"
                            href={"/newlyBuilt/instance/" + this[index].Id}
                            size="sm"
                          >
                            Ενέργειες
                          </Button>
                        </>
                        : <></>
                      }
                    </>
                    :
                    <>
                      <Button
                        color="warning"
                        href={"/newlyBuilt/instance/" + this[index].Id}
                        size="sm"
                      >
                        Ενέργειες
                      </Button>
                    </>
                  }

                  <Button
                    color="success"
                    href={"/newlyBuilt/comments/" + this[index].Id}
                    size="sm"
                  >
                    Σχόλια
                  </Button>
                  <Button
                    color="primary"
                    href={"/newlyBuilt/files/" + this[index].Id}
                    size="sm"
                  >
                    Έγγραφα
                  </Button>
                </React.Fragment>

            }, instances2);

            var instanceCopies = [...instances2];
            instanceCopies.forEach(object => {
              delete object['Id'];
            });

            {
              self.state.initRole.PersonRole == 'Admin' ?
                <>
                  {
                    this.setState({
                      loading:false,
                      datas: {
                        columns: [
                          {
                            label: 'Αριθμος Αιτησης ▼',
                            field: 'SerialNumber',
                            sort: 'asc',
                            width: 100
                          },
                          {
                            label: 'Κατασταση ▼',
                            field: 'Status',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ημ. Τελ. Ανανεωσης ▼',
                            field: 'SubmittedAt',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ανατεθηκε Σε ▼',
                            field: 'EditedBy',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Εργοδοτης ▼',
                            field: 'ergodotis',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Μηχανικος ▼',
                            field: 'Name',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Τοποθεσια ▼',
                            field: 'poli',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Μεταβαση στη Μελέτη▼',
                            field: 'Buttons',
                            sort: 'asc',
                            width: 150
                          }
                        ],
                        rows: instanceCopies
                      }
                    })
                  }
                </>
                :
                <>
                  {
                    this.setState({
                      loading:false,
                      datas: {
                        columns: [
                          {
                            label: 'Αριθμος Αιτησης ▼',
                            field: 'SerialNumber',
                            sort: 'asc',
                            width: 100
                          },
                          {
                            label: 'Κατασταση ▼',
                            field: 'Status',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ημ. Τελ. Ανανεωσης ▼',
                            field: 'SubmittedAt',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ανατεθηκε Σε ▼',
                            field: 'EditedBy',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Εργοδοτης ▼',
                            field: 'ergodotis',
                            sort: 'asc',
                            width: 150
                          },
                          // {
                          //   label: 'Μηχανικος ▼',
                          //   field: 'mixanikos',
                          //   sort: 'asc',
                          //   width: 150
                          // },
                          {
                            label: 'Τοποθεσια ▼',
                            field: 'poli',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Μεταβαση στη Μελέτη▼',
                            field: 'Buttons',
                            sort: 'asc',
                            width: 150
                          }
                        ],
                        rows: instanceCopies
                      }
                    })
                  }
                </>
            }
            // const instances = result[0];
            // this.setState({ instances });
          }
          catch
          {
            ////console.log('no category selected!');
          }
        })
    }
  }

  fetchDEDAAutopsies = (from = '', to = '') => {
    var fromArray = from.toLocaleDateString('en-GB').split('/');
    var toArray = to.toLocaleDateString('en-GB').split('/');

    var correctFromDate = fromArray[2] + '-' + fromArray[1] + '-' + fromArray[0];
    var correctToDate = toArray[2] + '-' + toArray[1] + '-' + toArray[0];

    //console.log(correctFromDate, correctToDate);

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/autopsiesfilter/", {
        method: 'post',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: JSON.stringify({
          dateFrom: correctFromDate,
          dateTo: correctToDate,
        })
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          var self = this;
          try {
            const instances2 = result[0];
            instances2.forEach(function (part, index) {
              this[index].SubmittedAt = this[index].SubmittedAt.split('T')[0];
              this[index].Status = this[index].Declined == true ? 'Αυτοψία Υπό Επανεξέταση' : this[index].Status == 1 ? 'Αυτοψία Μελέτης' : this[index].Status == 3 ? 'Βεβαιωμένη Αυτοψία' : '';
              var mythis = this;

              result[1][this[index].Id].forEach(function (element, counter) {
                if (element[1] == 'd87ac0b5-bcbb-420b-913f-1a95799e9530') {
                  mythis[index].meleti = element[2];
                }
                if (element[1] == 'fcf1056c-4d54-46c8-9494-0c0c0d5dc6bc') {
                  mythis[index].mixanikos = element[2];
                }
                if (element[1] == '6cc71d92-7162-4f30-b38c-8a953381b571') {
                  mythis[index].poli = element[2];
                }
              }, result[1][this[index].Id]);

              //console.log(result[1][this[index].Id][1][2]);

              // this[index].ergodotis = result[1][this[index].Id][0];
              this[index].Buttons =
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/newlyBuilt/autopsy/" + this[index].Id}
                    size="sm"
                  >
                    Στοιχεία
                  </Button>
                  {self.state.userDetails.PersonRole != 'User' ?
                    <>
                      {this[index].Status != 'Βεβαιωμένη Αυτοψία' ?
                        <>
                          <Button
                            color="warning"
                            href={"/newlyBuilt/instance/" + this[index].Id}
                            size="sm"
                          >
                            Ενέργειες
                          </Button>
                        </>
                        : <></>
                      }
                    </>
                    :
                    <>
                      <Button
                        color="warning"
                        href={"/newlyBuilt/instance/" + this[index].Id}
                        size="sm"
                      >
                        Ενέργειες
                      </Button>
                    </>
                  }

                  <Button
                    color="success"
                    href={"/newlyBuilt/comments/" + this[index].Id}
                    size="sm"
                  >
                    Σχόλια
                  </Button>
                  <Button
                    color="primary"
                    href={"/newlyBuilt/files/" + this[index].Id}
                    size="sm"
                  >
                    Έγγραφα
                  </Button>
                </React.Fragment>

            }, instances2);

            var instanceCopies = [...instances2];
            instanceCopies.forEach(object => {
              delete object['Id'];
            });

            {
              self.state.initRole.PersonRole == 'Admin' ?
                <>
                  {
                    this.setState({
                      autopsies: {
                        columns: [
                          {
                            label: 'Α/Α  ▼',
                            field: 'SerialNumber',
                            sort: 'asc',
                            width: 100
                          },
                          {
                            label: 'Συνδεδεμενη Μελετη ▼',
                            field: 'meleti',
                            sort: 'asc',
                            width: 100
                          },
                          {
                            label: 'Κατασταση ▼',
                            field: 'Status',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ημ. Τελ. Ανανεωσης ▼',
                            field: 'SubmittedAt',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ανατεθηκε Σε ▼',
                            field: 'EditedBy',
                            sort: 'asc',
                            width: 150
                          },
                          // {
                          //   label: 'Εργοδοτης ▼',
                          //   field: 'ergodotis',
                          //   sort: 'asc',
                          //   width: 150
                          // },
                          {
                            label: 'Μηχανικος ▼',
                            field: 'Name',
                            sort: 'asc',
                            width: 150
                          },
                          // {
                          //   label: 'Τοποθεσια ▼',
                          //   field: 'poli',
                          //   sort: 'asc',
                          //   width: 150
                          // },
                          {
                            label: 'Επιβλεπων Μηχανικος ▼',
                            field: 'SupervisingEngineerName',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Μεταβαση στην Αυτοψια ▼',
                            field: 'Buttons',
                            sort: 'asc',
                            width: 150
                          }
                        ],
                        rows: instanceCopies
                      }
                    })
                  }
                </>
                :
                <>
                  {
                    this.setState({
                      autopsies: {
                        columns: [
                          {
                            label: 'Α/Α  ▼',
                            field: 'SerialNumber',
                            sort: 'asc',
                            width: 100
                          },
                          {
                            label: 'Συνδεδεμενη Μελετη ▼',
                            field: 'meleti',
                            sort: 'asc',
                            width: 100
                          },
                          {
                            label: 'Κατασταση ▼',
                            field: 'Status',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ημ. Τελ. Ανανεωσης ▼',
                            field: 'SubmittedAt',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Ανατεθηκε Σε ▼',
                            field: 'EditedBy',
                            sort: 'asc',
                            width: 150
                          },
                          // {
                          //   label: 'Εργοδοτης ▼',
                          //   field: 'ergodotis',
                          //   sort: 'asc',
                          //   width: 150
                          // },
                          // {
                          //   label: 'Μηχανικος ▼',
                          //   field: 'mixanikos',
                          //   sort: 'asc',
                          //   width: 150
                          // },
                          // {
                          //   label: 'Τοποθεσια ▼',
                          //   field: 'poli',
                          //   sort: 'asc',
                          //   width: 150
                          // },
                          {
                            label: 'Επιβλεπων Μηχανικος ▼',
                            field: 'SupervisingEngineerName',
                            sort: 'asc',
                            width: 150
                          },
                          {
                            label: 'Μεταβαση στην Αυτοψια ▼',
                            field: 'Buttons',
                            sort: 'asc',
                            width: 150
                          }
                        ],
                        rows: instanceCopies
                      }
                    })
                  }
                </>
            }



            // const instances = result[0];
            // this.setState({ instances });
          }
          catch
          {
            ////console.log('no category selected!');
          }
        })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col" >
                      <h3 className="mb-0">Υποβεβλημένες Μελέτες</h3>
                    </div>
                    <div className="col text-right">
                      {this.state.userDetails.PersonRole != 'Admin' ?
                        <>
                          <Button
                            color="primary"
                            href={"/newlyBuilt/newresearch"}
                            size="sm"
                          >
                            Καταχώρηση νέας Μελέτης

                          </Button>
                          <Button
                            color="success"
                            href={"/newlyBuilt/newAutopsy"}
                            size="sm"
                          >
                            Καταχώρηση νέας Αυτοψίας

                          </Button>
                        </>
                        :
                        <></>
                      }


                    </div>
                    <div className="container-fluid" style={{ marginTop: '10px', marginBottom: '15px' }}>
                      <div className="row">


                        <div className="col-sm">
                          Από
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            // value="-"

                            name="startDate"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        {/* {this.getDates_call()} */}
                        <div className="col-sm">
                          Έως
                          <DatePicker
                            selected={this.state.otherDay}
                            onChange={this.handleChange2}
                            name="startDate"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>

                        {/* <div className="col-sm">
                          <Button
                            color="success"
                            // href={"/newlyBuilt/newinstance"}
                            size="sm"
                            style={{ marginTop: '25px' }}
                          >
                            Εφαρμογή

                          </Button>
                        </div> */}
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>


                      </div>
                    </div>

                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.state.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col" >
                      <h3 className="mb-0">Υποβεβλημένες Αυτοψίες</h3>
                    </div>
                    {/* <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/newlyBuilt/newresearch"}
                        size="sm"
                      >
                        Καταχώρηση νέας Μελέτης

                      </Button>
                      <Button
                        color="success"
                        href={"/newlyBuilt/newAutopsy"}
                        size="sm"
                      >
                        Καταχώρηση νέας Αυτοψίας

                      </Button>
                    </div> */}
                    <div className="container-fluid" style={{ marginTop: '10px', marginBottom: '15px' }}>
                      <div className="row">


                        <div className="col-sm">
                          Από
                          <DatePicker
                            selected={this.state.startDateAutopsy}
                            onChange={this.handleChangeAutopsy}
                            // value="-"

                            name="startDate"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>

                        <div className="col-sm">
                          Έως
                          <DatePicker
                            selected={this.state.otherDayAutopsy}
                            onChange={this.handleChangeAutopsy2}
                            name="startDate"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>

                        {/* <div className="col-sm">
                          <Button
                            color="success"
                            // href={"/newlyBuilt/newinstance"}
                            size="sm"
                            style={{ marginTop: '25px' }}
                          >
                            Εφαρμογή

                          </Button>
                        </div> */}
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>


                      </div>
                    </div>

                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.state.autopsies}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                    materialSearch
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div class='push'></div>
        </Container>
      </>
    );
  }
}

export default NewlyBuiltIndex;
