import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import axios from "axios";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";

import FilesComponent from "./dynamicActionsTypes/File";
import TextComponent from "./dynamicActionsTypes/Text";
import TextAreaComponent from "./dynamicActionsTypes/Text";
// import DropdownComponent from './dynamicActionsTypes/Dropdown'

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { ApiCallerHelperFiles } from "../../../api/apiCallerHelper.files";
import { WorkflowHelper } from "../../../common/workflowHelper";
import { UserRoleHelper } from "../../../common/userRoleHelper";
import { ValidationHelper } from "../../../common/validationHelper";
import { RedirectHelper } from "../../../common/redirectHelper";
import { WorkflowInstancesStatusTypes } from "../../../common/enums/workflowInstancesStatusTypes.enum";
import { WorkflowStepActionIdTypes } from "../../../common/enums/workflowStepActionIdTypes.enum";

class NewlyBuiltInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      instance: [],
      steps: undefined,
      files: [],
      enabled: true,
      fileUpload: null,
      buttonCondition: 0,
      consumerType: 0,
      consumerCategory: 0,
      type: "",
      removeActions: 0,
      logged_user: undefined,
      user: [],
      displayprop: false,
      disableCompleteButton: true,
    };

    this.changeType = this.changeType.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.isDeleteFileEnabledByUserRole =
      this.isDeleteFileEnabledByUserRole.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this._visibilityrules.hasPermissionToUploadFile =
      this._visibilityrules.hasPermissionToUploadFile.bind(this);
  }

  _visibilityrules = {
    _getHasOverideRules: [
      {
        actionNames: ["Σχόλιο Μερικής Παραλαβής"],
        isVisible: (user, action, data) => {
          console.log(user);
          if (UserRoleHelper.isUser(user) || UserRoleHelper.isAdmin(user)) {
            return false;
          }

          return true;
        },
      },
      {
        actionNames: ["Αριθμός Προηγούμενης Μελέτης"],
        isVisible: (user, action, data) => {
          if (UserRoleHelper.isUser(user) || UserRoleHelper.isAdmin(user)) {
            if (data != "") return true;
            else return false;
          }

          return true;
        },
      },
      {
        actionNames: ["Θεωρημένη Μελέτη"],
        isVisible: (user, action, data) => {
          if (UserRoleHelper.isAdmin(user)) {
            return true;
          }
          return false;
        },
        OverrideFilePermissions: (user) => {
          if (UserRoleHelper.isAdmin(user))
            return this._visibilityrules
              .hasPermissionToUploadSignedFileDocuments;
          if (UserRoleHelper.isUser(user))
            return this._visibilityrules.hasPermissionToUploadFile;
        },
        OverrideDeletePermissions: (user) => {
          if (UserRoleHelper.isAdmin(user)) {
            return this.EnableDeleteForAdminSignedDocuments;
          }
          return this.isDeleteFileEnabledByUserRole;
        },
      },
    ],
    getHasOverideRules: function (user, actionName, data) {
      var exists =
        this._getHasOverideRules.filter((x) =>
          x.actionNames.includes(actionName)
        )?.[0] ?? undefined;
      if (exists == undefined) return true;
      return exists.isVisible(user, actionName, data);
    },
    getHasOverideRules: function (user, actionName, data) {
      var exists =
        this._getHasOverideRules.filter((x) =>
          x.actionNames.includes(actionName)
        )?.[0] ?? undefined;
      if (exists == undefined) return true;
      return [
        exists.isVisible(user, actionName, data),
        exists.hasOwnProperty("OverrideFilePermissions")
          ? exists.OverrideFilePermissions(user)
          : undefined,
        exists.hasOwnProperty("OverrideDeletePermissions")
          ? exists.OverrideDeletePermissions(user)
          : undefined,
      ];
    },
    hasPermissionToUploadFile: function (user) {
      if (UserRoleHelper.isAdmin(user)) return false;
      return true;
    },
    hasPermissionToUploadSignedFileDocuments: function (user) {
      if (UserRoleHelper.isAdmin(user)) return true;
      return false;
    },
  };

  _getWorkflowinstancesById = async (WorkflowInstanceId) => {
    const res =
      await ApiCallerHelper.Workflowinstances.getWorkflowinstancesByIdAsync(
        WorkflowInstanceId
      );
    this.setState({
      instance: res.data.instance,
      steps: res.data.stepsInfused,
      fileUpload: null,
      logged_user: res.data.logged_user,
      user: res.data.user,
    });

    if (res.data.instance.Status == WorkflowInstancesStatusTypes.COMPLETED) {
      if (
        res.data.instance.WorkflowId ==
        WorkflowHelper.WorkflowIdTypes.NEWLY_BUILD_AUTOPSY
      ) {
        RedirectHelper.RedirectTo.autopsy(res.data.instance.Id);
        // window.location.replace("/newlyBuilt/autopsy/" + res.data.instance.Id + "/");
      } else {
        RedirectHelper.RedirectTo.details(res.data.instance.Id);
        // window.location.replace("/newlyBuilt/details/" + res.data.instance.Id + "/");
      }
    }
  };

  isDeleteFileEnabledByUserRole(file) {
    return ValidationHelper.Files.isDeleteFileEnabledByUserRole(
      this.state.logged_user,
      this.state.instance.SubmittedAt,
      file.UploadDate
    );
  }

  EnableDeleteForAdminSignedDocuments(file) {
    return true;
  }

  hasSignedDocumentUploaded = (data) => {
    data.forEach((data) => {
      if (data["ActionId"] == WorkflowStepActionIdTypes.YPOGEGRAMMENA_EGGRAFA) {
        this.setState({
          disableCompleteButton: false,
        });
      }
    });
  };

  async componentDidMount() {
    const handle = this.props.match.params.id;
    //console.log(handle);
    this._getWorkflowinstancesById(handle);

    ValidationHelper.Files.isDocumentVisibleUploadDisabled(
      this.state.logged_user
    );

    const result2 = await axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/files/status/" + handle + "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          files: res.data,
        });

        this.hasSignedDocumentUploaded(res.data);
        //console.log(this.state.files);
      });

    if (this.state.instance.Status == 2) {
      const result3 = await axios
        .get(
          process.env.REACT_APP_API_LINK + "/api/consumer/type/" + handle + "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          //console.log(res);
        });
    }
  }

  saveInstance = (stepStatus) => {
    //console.log(this.state.steps);
    this.setState({ removeActions: 1 });

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/update/" +
          this.state.instance.Id +
          "/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify(this.state.steps[stepStatus - 1]),
        }
      ).then((data) => {
        //console.log(data);
        window.location.replace("/consumer/index");
      });
    }
  };

  declineInstance = (stepStatus) => {
    //console.log(this.state.steps);
    this.setState({ removeActions: 1 });

    window.location.replace("/newlyBuilt/addcomment/" + this.state.instance.Id);
  };

  submitInstance = (stepStatus) => {
    //console.log(stepStatus);

    //console.log(this.state.steps);
    this.setState({ removeActions: 1 });

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/approve/" +
          this.state.instance.Id +
          "/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify(this.state.steps[stepStatus - 1]),
        }
      ).then((data) => {
        console.log(data);
        let stepData = [...this.state.steps];

        if (stepStatus == 3) {
        } else {
          if (this.state.instance.Declined) {
            window.location.replace("/newlyBuilt/index/");
          } else {
            // window.location.reload(false);
          }
        }
      });
    }
  };

  updateFile(evt, test) {
    //console.log(test);
    this.setState({ fileUpload: evt.target.files[0] });
  }

  deleteFile = (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          axios
            .get(
              process.env.REACT_APP_API_LINK +
                "/api/files/status/" +
                this.state.instance.Id +
                "/",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              this.setState({
                loading: false,
                files: res.data,
              });

              this.hasSignedDocumentUploaded(res.data);

              //console.log(res);
            });
        });
    }
  };

  uploadFile(evt, fileid) {
    const formData = new FormData();
    var res = fileid.split("/");

    if (evt.target.files.length > 1) {
      var self = this;

      for (let i = 0; i < evt.target.files.length; i++) {
        let file = evt.target.files.item(i);
        const newformData = new FormData();
        newformData.append("files", file);

        fetch("https://filemanager.deda.gr/", {
          method: "POST",
          body: newformData,
        })
          .then((response) => response.json())
          .then((result) => {
            //console.log('Success:', result);

            fetch(process.env.REACT_APP_API_LINK + "/api/files/new/", {
              method: "POST",
              headers: new Headers({
                Authorization: "Bearer " + localStorage.getItem("token"),
              }),
              body: JSON.stringify({
                Name: result[0].name,
                Path: result[0].path,
                Size: result[0].size,
                Type: result[0].type,
                InstanceId: self.state.instance.Id,
                StepId: res[0],
                ActionId: res[1],
              }),
            }).then((data) => {
              //console.log(data);

              axios
                .get(
                  process.env.REACT_APP_API_LINK +
                    "/api/files/status/" +
                    self.state.instance.Id +
                    "/",
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then((res) => {
                  self.setState({
                    loading: false,
                    files: res.data,
                  });

                  this.hasSignedDocumentUploaded(res.data);

                  //console.log(res);
                });
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } else {
      // Update the formData object
      formData.append("files", evt.target.files[0]);

      fetch("https://filemanager.deda.gr/", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          //console.log('Success:', result);

          fetch(process.env.REACT_APP_API_LINK + "/api/files/new/", {
            method: "POST",
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("token"),
            }),
            body: JSON.stringify({
              Name: result[0].name,
              Path: result[0].path,
              Size: result[0].size,
              Type: result[0].type,
              InstanceId: this.state.instance.Id,
              StepId: res[0],
              ActionId: res[1],
            }),
          }).then((data) => {
            //console.log(data);

            axios
              .get(
                process.env.REACT_APP_API_LINK +
                  "/api/files/status/" +
                  this.state.instance.Id +
                  "/",
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((res) => {
                this.setState({
                  loading: false,
                  files: res.data,
                });

                this.hasSignedDocumentUploaded(res.data);

                //console.log(res);
              });
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  updateInputValue(evt) {
    console.log(1);
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          let tempAction = { ...data[i].actions[j] };
          if (tempAction.action.Type == "radio") {
            tempAction.data.Data = "";
            data[i].actions[j] = tempAction;
          }
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            if (tempAction.action.Target == "limit14") {
              if (evt.target.value.length > 14) {
                evt.target.value = evt.target.value.substring(
                  0,
                  evt.target.value.length - 1
                );
              }
            }

            if (tempAction.action.Target == "limit9") {
              if (evt.target.value.length > 9) {
                evt.target.value = evt.target.value.substring(
                  0,
                  evt.target.value.length - 1
                );
              }
            }

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
          }
        }
      }
    }
  }

  changeType(event) {
    //console.log(event.target.value);
    this.setState({ consumerType: event.target.value });
  }

  changeCategory(event) {
    //console.log(event.target.value);
    this.setState({ consumerCategory: event.target.value });
  }

  render() {
    if (
      this.state.loading ||
      this.state.logged_user == undefined ||
      this.state.steps == undefined
    ) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    }

    return (
      <>
        <InstanceHeader />
        <div
          className="header pb-4"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          ></CardTitle>

                          <CardBody>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Α/Α: &nbsp;{" "}
                              </span>{" "}
                              {this.state.instance.SerialNumber}/
                              {new Date().getFullYear()}
                            </Row>
                            {WorkflowHelper.isWorkflowIdTypeNewlyBuildAutopsyFromInstance(
                              this.state.instance
                            ) &&
                            UserRoleHelper.isEngineer(
                              this.state.logged_user
                            ) ? (
                              <div></div>
                            ) : (
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Ημερομηνία Υποβολής: &nbsp;{" "}
                                </span>{" "}
                                {Moment(this.state.instance.StartedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </Row>
                            )}
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Ημερομηνία Τελευταίας Τροποποίησης: &nbsp;{" "}
                              </span>{" "}
                              {Moment(this.state.instance.SubmittedAt)
                                .locale("el")
                                .format("dddd, DD MMM YYYY")}
                            </Row>
                          </CardBody>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {this.state.steps.map((dynamicData) => (
          <>
            {dynamicData.step.Status == this.state.instance.Status ? (
              <>
                {dynamicData.step.Conditional &&
                (!this.state.instance.Paused ||
                  this.state.logged_user.PersonRole == "Admin") ? (
                  <Container className="mt-2" fluid>
                    <Row className="mt-5">
                      <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                              <Col xs="8">
                                <h3 className="mb-0">
                                  {dynamicData.step.Name}
                                </h3>{" "}
                                <br />
                                {UserRoleHelper.isAdmin(
                                  this.state.logged_user
                                ) && (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    href={
                                      "/newlyBuilt/formvisa/" +
                                      this.props.match.params.id
                                    }
                                    target = {'_blank'}
                                    // disabled={this.state.disableFormVisaButton}
                                  >
                                    Φόρμα Θεώρησης
                                  </Button>
                                )}
                              </Col>
                              {this.state.removeActions ? (
                                <> </>
                              ) : (
                                <>
                                  {this.state.logged_user.PersonRole ==
                                  "Admin" ? (
                                    <Col className="text-right" xs="4">
                                      <Button
                                        color="success"
                                        href={
                                          "/newlyBuilt/details/" +
                                          this.state.instance.Id
                                        }
                                        onClick={() => {
                                          this.submitInstance(
                                            dynamicData.step.Status
                                          );
                                        }}
                                        size="sm"
                                        disabled={
                                          this.state.disableCompleteButton
                                        }
                                      >
                                        Θεώρηση
                                      </Button>
                                      <Button
                                        color="danger"
                                        href="#"
                                        onClick={() => {
                                          this.declineInstance(
                                            dynamicData.step.Status
                                          );
                                        }}
                                        size="sm"
                                      >
                                        Επανεξέταση
                                      </Button>
                                    </Col>
                                  ) : (
                                    <Col className="text-right" xs="4">
                                      <Button
                                        color="primary"
                                        href="#"
                                        onClick={() => {
                                          this.saveInstance(
                                            dynamicData.step.Status
                                          );
                                        }}
                                        size="sm"
                                      >
                                        Αποθήκευση
                                      </Button>
                                      <Button
                                        color="success"
                                        href="#"
                                        onClick={() => {
                                          this.submitInstance(
                                            dynamicData.step.Status
                                          );
                                        }}
                                        size="sm"
                                      >
                                        Υποβολή
                                      </Button>
                                    </Col>
                                  )}
                                </>
                              )}
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Form>
                              <div className="pl-lg-4">
                                {/* {this.state.instance.Declined && this.state.logged_user.PersonRole == 'User' && this.state.instance.WorkflowId == '7f4445d1-841a-4fda-8809-6225ceabd2c5' ?
                                                                    <>
                                                                        <h4>Σας δίνεται η δυνατότητα υποβολής Σχολίου προς τον Μηχανικό στο πεδίο παρακάτω με όνομα "Σχόλιο προς Μηχανικό"</h4><br />
                                                                    </> : <></>
                                                                }
                                                                {this.state.instance.Declined && this.state.logged_user.PersonRole == 'User' && this.state.instance.WorkflowId == '825b7591-ad30-45d6-83a7-e5ea72dee743' ?
                                                                    <>
                                                                        <h4>Σας δίνεται η δυνατότητα υποβολής Παρατηρήσεων προς τον Μηχανικό στο πεδίο παρακάτω με όνομα "Παρατηρήσεις"</h4><br />
                                                                    </> : <></>
                                                                } */}
                                <Row>
                                  {dynamicData.actions.map((dynamicActions) => (
                                    <>
                                      {console.log(
                                        this._visibilityrules.getHasOverideRules(
                                          this.state.logged_user,
                                          dynamicActions.action.Name,
                                          dynamicActions.data.Data
                                        )
                                      )}
                                      {UserRoleHelper.isRunTimeUser(
                                        dynamicActions.action
                                      ) &&
                                        (this._visibilityrules.getHasOverideRules(
                                          this.state.logged_user,
                                          dynamicActions.action.Name,
                                          dynamicActions.data.Data
                                        )?.[0] ??
                                          true) && (
                                          <>
                                            <Col lg="6">
                                              <FormGroup>
                                                <label className="form-control-label">
                                                  {dynamicActions.action.Name}
                                                </label>

                                                {dynamicActions.action.Type ==
                                                  "file" && (
                                                  <FilesComponent
                                                    StepAndActionId={
                                                      dynamicData.step.Id +
                                                      "/" +
                                                      dynamicActions.action.Id
                                                    }
                                                    actionId={
                                                      dynamicActions.action.Id
                                                    }
                                                    files={this.state.files}
                                                    hasPermissionToUpload={
                                                      this._visibilityrules.getHasOverideRules(
                                                        this.state.logged_user,
                                                        dynamicActions.action
                                                          .Name,
                                                        dynamicActions.data.Data
                                                      )?.[1] ??
                                                      this._visibilityrules.hasPermissionToUploadFile(
                                                        this.state.logged_user
                                                      )
                                                    }
                                                    hasDeletePermission={
                                                      this._visibilityrules.getHasOverideRules(
                                                        this.state.logged_user,
                                                        dynamicActions.action
                                                          .Name,
                                                        dynamicActions.data.Data
                                                      )?.[2] ??
                                                      this
                                                        .isDeleteFileEnabledByUserRole
                                                    }
                                                    deleteFunc={this.deleteFile}
                                                    _onChange={this.uploadFile}
                                                  />
                                                )}

                                                {(dynamicActions.action.Type ==
                                                  "text" ||
                                                  dynamicActions.action.Type ==
                                                    "number" ||
                                                  dynamicActions.action.Type ==
                                                    "dropdown") && (
                                                  <TextComponent
                                                    StepAndActionId={
                                                      dynamicData.step.Id +
                                                      "/" +
                                                      dynamicActions.action.Id
                                                    }
                                                    defaultValue={
                                                      dynamicActions.data.Data
                                                    }
                                                    placeholder={
                                                      dynamicActions.action
                                                        .Description
                                                    }
                                                    disabled={
                                                      !dynamicActions.action
                                                        .Required
                                                    }
                                                    _onChange={
                                                      this.updateInputValue
                                                    }
                                                  />
                                                )}
                                                {dynamicActions.action.Type ==
                                                  "textarea" && (
                                                  <TextAreaComponent
                                                    StepAndActionId={
                                                      dynamicData.step.Id +
                                                      "/" +
                                                      dynamicActions.action.Id
                                                    }
                                                    defaultValue={
                                                      dynamicActions.data.Data
                                                    }
                                                    placeholder={
                                                      dynamicActions.action
                                                        .Description
                                                    }
                                                    disabled={
                                                      !dynamicActions.action
                                                        .Required
                                                    }
                                                    _onChange={
                                                      this.updateInputValue
                                                    }
                                                  />
                                                )}

                                                {/* {dynamicActions.action.Type == 'dropdown' &&
                                                                                                <TextComponent
                                                                                                    options={JSON.parse(dynamicActions.action.Target)}
                                                                                                    StepAndActionId={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                    placeholder={dynamicActions.action.Description}
                                                                                                    disabled={!dynamicActions.action.Required}
                                                                                                    _onChange={this.updateInputValue}
                                                                                                />
                                                                                            } */}
                                              </FormGroup>
                                            </Col>
                                          </>
                                        )}
                                    </>
                                  ))}
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <Container className="mt-2" fluid>
                    <Row className="mt-5">
                      <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                          <CardBody>
                            <Form>
                              <div className="pl-lg-4">
                                <Row>
                                  <Col lg="12">
                                    <p style={{ fontSize: "18px" }}>
                                      Θα ενημερωθείτε σύντομα για την εξέλιξη
                                      του αιτήματός σας.
                                    </p>
                                    <Button
                                      color="primary"
                                      href={
                                        WorkflowHelper.isWorkflowIdTypeNewlyBuildStudyFromInstance(
                                          this.state.instance
                                        )
                                          ? "/newlyBuilt/details/"
                                          : "/newlyBuilt/autopsy/" +
                                            this.state.instance.Id
                                      }
                                      size="lg"
                                    >
                                      Στοιχεία Αιτήματος
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                )}
              </>
            ) : (
              <> </>
            )}
          </>
        ))}
      </>
    );
  }
}

export default NewlyBuiltInstance;
