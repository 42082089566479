import User from "views/examples/newlyBuilt/newlyBuiltProfile";
import { UserRoleHelper } from "./userRoleHelper";

export const ValidationHelper = {
  Files: {
    isDeleteFileEnabledByUserRole: isDeleteFileEnabledByUserRole,
    isDocumentVisibleUploadDisabled: isDocumentVisibleUploadDisabled
  },
};

function isDeleteFileEnabledByUserRole(
  user,
  workflowInstanceSubmittedDate,
  fileUploadDate
) {
  function _isFilePreviouslySubmitted(workflowInstanceSubmittedDate, fileUploadDate) {
    return Date.parse(fileUploadDate) < Date.parse(workflowInstanceSubmittedDate);
  }

  if (UserRoleHelper.isEngineer(user)) {
    return !_isFilePreviouslySubmitted(workflowInstanceSubmittedDate, fileUploadDate);
  }
  return false;
}

function isDocumentVisibleUploadDisabled(
  user
) {
  if (UserRoleHelper.isAdmin) {
    console.log('Admin')
  }
}