import { UserRoleTypes, SecondaryRoleTypes, actionRunTimeTypes } from "./enums/userRoleTypes.enum";
export const UserRoleHelper = {
  UserRoleTypes: UserRoleTypes,
  isEngineer: isEngineer,
  isUser: isUser,
  isAdmin: isAdmin,
  isController: isController,
  isExternalController: isExternalController,
  isParentController: isParentController,
  isRunTimeUser: isRunTimeUser
};

function isUser(user) {
  return user.PersonRole.toLowerCase() == UserRoleTypes.USER.toLowerCase();
}

function isAdmin(user) {
  return user.PersonRole.toLowerCase() == UserRoleTypes.ADMIN.toLowerCase();
}

/** ΜΗΧΑΝΙΚΟΣ / ΜΕΛΕΤΗΤΗΣ */
function isEngineer(user) {
  return isUser(user);
}

/** ΕΛΕΓΚΤΗΣ */
function isController(user) {
  return isAdmin(user);
}

/** ΑΦΟΡΑ ΤΟΥΣ ΕΛΕΓΚΤΕΣ ΠΟΥ ΕΙΝΑΙ ΥΠΑΛΛΗΛΟΙ ΤΗΣ ΔΕΔΑ */
function isExternalController(user) {
  return user.SecondaryRole.toLowerCase() == SecondaryRoleTypes.EXTERNAL.toLowerCase();
}

/** ΑΦΟΡΑ ΤΟΥΣ ΕΛΕΓΚΤΕΣ ΠΟΥ ΕΙΝΑΙ ΕΞΩΤΕΡΙΚΟΙ ΣΥΝΕΡΓΑΤΕΣ ΤΗΣ ΔΕΔΑ */
function isParentController(user) {
  return user.SecondaryRole.toLowerCase() == SecondaryRoleTypes.PARENT.toLowerCase();
}

function isRunTimeUser(action) {
  return action.RunTime.toLowerCase() == actionRunTimeTypes.USER.toLowerCase();
}