import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';

import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

class NewComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            loading: true,
            instanceid: '',
            enabled: true,
            fileUpload: null,
            comment: '',
            removeActions: 0
        };

        this.changeComment = this.changeComment.bind(this);
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;
        //console.log(handle);

        this.setState({ instanceid: handle });
    };

    submitComment = stepStatus => {
        //console.log(this.state.steps);
        this.setState({ removeActions: 1 });

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);
                    if (result.PersonRole == 'Admin') {

                        fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/decline/' + this.state.instanceid + "/", {
                            method: 'POST',
                            headers: new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            }),
                            body: JSON.stringify({
                                'Comment': this.state.comment
                            })
                        })
                            .then(data => {
                                //console.log(data);

                                window.location.replace("/newlyBuilt/comments/" + this.state.instanceid);
                            })
                    }
                    else {
                        fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/addcomment/' + this.state.instanceid + "/", {
                            method: 'POST',
                            headers: new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            }),
                            body: JSON.stringify({
                                'Comment': this.state.comment
                            })
                        })
                            .then(data => {
                                //console.log(data);

                                window.location.replace("/newlyBuilt/comments/" + this.state.instanceid);
                            })
                    }

                })

        }
    }

    changeComment(event) {
        //console.log(event.target.value);
        this.setState({ comment: event.target.value });
    }

    render() {
        return (
            <>
                <GenericHeader />
                <Container className="mt-2" fluid>
                    <Row className="mt-1">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Υποβολή Σχολίου</h3>
                                        </Col>
                                        {this.state.removeActions ?
                                            <> </>
                                            : <>
                                                <Col className="text-right" xs="4">
                                                    <Button
                                                        color="success"
                                                        href="#"
                                                        onClick={() => { this.submitComment() }}
                                                        size="sm"
                                                    >
                                                        Αποστολή
                                                    </Button>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder={'Νέο σχόλιο'}
                                                            type={'textarea'}
                                                            onChange={evt => this.changeComment(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NewComment;
