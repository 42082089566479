export const UserRoleTypes = {
    ADMIN : 'Admin',
    USER : 'User' //Engineer
}	

export const SecondaryRoleTypes = {
    PARENT : 'Parent',
    EXTERNAL : 'External'
}	

export const actionRunTimeTypes = {
    USER: 'User'
}